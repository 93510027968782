<template>
  <v-container
    fluid
    :style="inlineItem ? 'overflow: auto' : ''"
  >
    <v-row
      v-if="inlineItem"
      v-show="selectedItemId"
    >
      <v-col
      >
        <Action
          ref="action"
          v-model="selectedItemId"
          :fixedClientId="fixedClientId"
          :inlineItem="inlineItem"
          @refreshList="getItems()"
          @editMode="(state) => {editMode = state}"
        >
        </Action>
      </v-col>
    </v-row>

    <v-row
      class="overflowLayout"
      :style="!selectedItemId ? 'margin-top: -12px' : ''"
    >
      <v-col
        class="overflowFlex"
      >
        <v-data-table
          :class="fixedClientId ? 'actionsTable hideMatchcodeColumn' : 'actionsTable'"
          style="white-space: nowrap;"
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :footer-props="footerProps"
          :server-items-length="totalItems"
          @update:options="updateQuery()"
          fixed-header
          mobile-breakpoint="0"
          :height="(inlineItem || fixedClientId) ? 'auto' : 'calc(100vh - 130px)'"
          @click:row="showItem"
          :value="selected"
          single-select
        >
          <template v-slot:[`body.prepend`]="{ headers }">
            <tr
              class="tableFilter"
              py-3
            >
              <td
                v-for="(header,key) in headers"
                :key="key"
                :class="header.class ? 'pb-1 ' + header.class : 'pb-1'"
              >
                <v-text-field
                  v-if="header.filterType === 'text'"
                  v-model="header.filterValue"
                  hide-details
                  single-line
                  dense
                  clearable
                  @keyup.esc="header.filterValue = ''"
                ></v-text-field>
                <v-select
                  v-else-if="header.filterType === 'select'"
                  flat
                  hide-details
                  dense
                  v-model="header.filterValue"
                  :items="header.selectList"
                ></v-select>
              </td>
            </tr>
          </template>
          <template
            v-slot:item="{ item, index }"
          >
            <tr
              :key="item.actionId"
              @click.stop="showItem(item)"
              :class="(index === selectedIndex) ? 'v-data-table__selected' : ''"
            >

              <td class="text-right">{{ item.actionId }}</td>
              <td>{{ item.actionDate }}</td>
              <td>{{ item.respPers }}</td>
              <td
                v-if="!fixedClientId"
              >{{ item.matchcode }}
              </td>
              <td>{{ item.recCreator }}</td>
              <td>{{ item.personName }}</td>
              <td>{{ item.actionType }}</td>
              <td>{{ item.actionSubject }}</td>
              <td>{{ item.showAgain }}</td>
              <td>{{ item.showAgainDate }}</td>
            </tr>
          </template>
          <template v-slot:[`footer.prepend`]
            v-if="!(fixedClientId)"
          >
            <v-btn
              v-if="userCanEdit"
              @click="createItem ()"
              title="Crear acción (Ctrl. + Ins.)"
              small
              fab
              color="primary"
              elevation="2"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'
import Action from '@/views/Action'
// import EventBus from '@/components/EventBus'

export default {
  mixins: [ItemsMixins],
  components: {
    Action
  },
  props: {
    // optional fixedClientId passed if called from Client.vue
    fixedClientId: String
  },
  data () {
    return {
      editRight: 'CAN_WRITE',
      itemsName: 'actions',
      itemName: 'action',
      inlineItem: this.$store.state.showItemInline,
      headers: [
        {
          text: 'Id',
          align: 'right',
          value: 'actionId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number',
          width: 100
        },
        {
          text: 'Fecha',
          value: 'actionDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Com.',
          value: 'respPers',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('usersSelect', 'userId'),
          filterSearch: 'full'
        },

        {
          text: 'Match',
          value: 'matchcode',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy',
          class: 'matchcodeColumn'
        },
        {
          text: 'Usuario',
          value: 'recCreator',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('usersSelect', 'userId'),
          filterSearch: 'full'
        },
        {
          text: 'Persona',
          value: 'personName',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'Tipo',
          value: 'actionType',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('actiontypeSelect'),
          filterSearch: 'full'
        },

        {
          text: 'Action',
          value: 'actionSubject',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Pdte.',
          value: 'showAgain',
          filterType: 'select',
          filterValue: '',
          selectList: [
            { text: 'All', value: '' }, 'no', 'yes'
          ],
          filterSearch: 'full'
        },
        {
          text: 'Fecha',
          value: 'showAgainDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        }
      ]
    }
  }
}
</script>
<style>
  .actionsTable {
    white-space: nowrap;
  }

  .actionsTable.hideMatchcodeColumn .matchcodeColumn {
    display: none !important;
  }

</style>
