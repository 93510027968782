<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-if="item.actionId"
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        :class="inlineItem ? 'inlineItem' : ''"
        :elevation="inlineItem ? '1' : '0'"
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            fluid
            :class="inlineItem ? 'pa-0' : 'pa-2'"
          >
            <v-row
              dense
            >
              <v-col
                cols="4"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  ref="focussedInput"
                  v-model="item.actionType"
                  :rules="requiredRules"
                  label="Tipo"
                  :items="actiontypeSelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>

              <v-col
                cols="4"
              >
                <v-autocomplete
                  dense
                  :readonly="!editMode"
                  :disabled="(typeof fixedClientId !== 'undefined')"
                  persistent-placeholder
                  filled
                  v-model="item.clientId"
                  :rules="requiredRules"
                  label="Empresa"
                  :items="clientsSelect"
                  item-text="matchcode"
                  item-value="clientId"
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="4"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.personId"
                  label="Persona"
                  :items="personsSelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=255
                  v-model="item.actionSubject"
                  :rules="requiredRules"
                  label="Asunto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.actionText"
                  rows="2"
                  label="Detalle"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  filled
                  height="26px"
                  type="date"
                  :rules="requiredRules"
                  v-model="item.actionDate"
                  label="Fecha"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.showAgain"
                  label="Pendiente"
                  :items="[{text: 'Sí', value: 1}, {text: 'No', value: 0}]"
                >
                </v-select>
              </v-col>
              <v-col
                cols="4"
              >
                <v-text-field
                  v-if="item.showAgain === 1"
                  dense
                  :readonly="!editMode"
                  filled
                  height="26px"
                  type="date"
                  v-model="item.showAgainDate"
                  label="Fecha pendiente"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div
            class="text-caption"
          >
            <span v-if="item.actionId === 'new_'">Pendiente de guardar.</span>
            <span v-else>
              Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
            </span>
            <span v-if="item.recChanger">
              Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
            </span>
          </div>
        </v-card-text>
        <v-card-actions
        >
          <v-btn
            v-if="editMode && item.actionId !== 'new_' && userCanEdit"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
            title="Cancelar y deshacer cambios (Esc)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode && userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="!editMode"
            @click="close"
            text
            :title="inlineItem ? 'Cerrar (Esc)' : 'Volver a lista (Esc)'"

          >
            {{ inlineItem ? 'Cerrar' :  'Volver' }}
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit"
            color="primary"
            @click="editMode = true"
            text
            title="Editar (F2)"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import api from '../api'
import ItemMixins from '@/utils/ItemMixins'

export default {
  mixins: [ItemMixins],
  props: {
    // value represents selectedItemId
    value: [String, Number],
    fixedClientId: String,
    inlineItem: Boolean
  },
  data () {
    return {
      editRight: 'CAN_WRITE',
      itemsName: 'actions', // needed for ItemMixins
      itemName: 'action',

      actiontypeSelect: this.$store.state.actiontypeSelect,
      personsSelect: [],
      requiredRules: [
        (v) => !!v || '¡Información requerida'
      ]
    }
  },
  watch: {
    'item.clientId': function (val) {
      this.getClientPersonsSelect(val)
    }
  },
  computed: {
    // computed to respond to added clients
    clientsSelect () {
      return this.$store.state.clientsSelect
    }
  },
  methods: {
    createItem () {
      this.item = {
        actionId: 'new_',
        clientId: +this.fixedClientId || null,
        personId: null,
        actionDate: new Date().toISOString().slice(0, -14),
        actionType: null,
        showAgain: 0,
        showAgainDate: null,
        actionSubject: '',
        actionText: null
      }
      this.editMode = true
      this.modelItemId = 'new_'
    },
    getClientPersonsSelect (clientId) {
      if (!clientId) {
        this.personsSelect = []
        return
      }
      api.getClientPersonsSelect(clientId)
        .then(data => {
          this.personsSelect = [{ key: null, value: '' }, ...data]
        })
    }
  }
}
</script>
