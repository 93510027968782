  <template>
    <form
      enctype="multipart/form-data"
      novalidate
    >
      <v-btn
        :color="btnColor || null"
        :loading="loading"
        @click="$refs.uploadInput.click()"
        text
        :title="btnTitle || null"
      >
        {{ btnText }}
      </v-btn>
      <!-- hiden upload input -->
      <input
        :multiple="multiple || false"
        ref="uploadInput"
        type="file"
        :name="inputName"
        @change="uploadFiles($event)"
        :accept="acceptFileType || '*/*'"
        class="d-none"
      >
    </form>
</template>

<script>
import api from '../api'

export default {
  props: {
    inputName: String,
    uploadPath: String,
    data: Object,
    btnText: String,
    btnTitle: String,
    btnColor: String,
    multiple: Boolean,
    acceptFileType: String,
    successText: String
  },
  data () {
    return {
      loading: false,
      uploadFieldName: 'fileupload'
    }
  },
  methods: {
    uploadFiles (e) {
      const fieldName = e.target.name
      const fileList = e.target.files
      console.log(fieldName, fileList)
      if (!fileList.length) {
        return
      }

      const formData = new FormData()
      // append additonal form data
      if (this.data) {
        console.log(this.data)
        Object.entries(this.data)
          .map(([key, val]) => {
            return formData.append(key, val)
          })
      }

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          return formData.append(fieldName, fileList[x], fileList[x].name)
        })
      console.log(formData)
      // upload data to the server
      this.loading = true
      api.uploadFile(formData, this.uploadPath)
        .then(() => {
          this.loading = false
          this.$refs.uploadInput.value = null
          this.$store.commit('alert',
            {
              show: true,
              text: this.successText || 'Fichero subido',
              type: 'success'
            }
          )
        })
        .catch(e => {
          this.loading = false
          this.$refs.uploadInput.value = null
          this.$store.commit('alert',
            {
              show: true,
              text: e.message || 'Error al subir fichero',
              type: 'error'
            }
          )
        })
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
