<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
  >
    <v-card
      flat
    >
      <v-card-text
        class="pa-0"
      >
        <v-container
          fluid
          class="pa-1"
        >
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.deliveryAddressName"
                  :rules="requiredRules"
                  label="Nombre dirección"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.company"
                  :rules="requiredRules"
                  label="Empresa"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.company2"
                  label="Empresa (2da línea)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.contact"
                  :rules="requiredRules"
                  label="Contacto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=20
                  v-model="item.phone"
                  :rules="requiredRules"
                  label="Teléfono de contacto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.street"
                  :rules="requiredRules"
                  label="Calle"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              dense
            >
              <v-col
                cols="4"
                sm="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=10
                  v-model="item.zipCode"
                  :rules="requiredRules"
                  label="C.P."
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=25
                  v-model="item.city"
                  :rules="requiredRules"
                  label="Ciudad"
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=15
                  v-model="item.province"
                  :rules="requiredRules"
                  label="Provincia"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.isoCountry"
                  :rules="requiredRules"
                  label="País"
                  :items="isocountrySelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=120
                  v-model="item.forwarderComment"
                  label="Instrucciones para el transportista"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              dense
              class="ma-0"
            >
              <v-col
                cols="12"
              >
                <div
                  class="text-caption"
                >
                <span v-if="item.deliveryAddressId === 'new_'">Pendiente de guardar.</span>
                <span v-else>
                  Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
                </span>
                <span v-if="item.recChanger">
                  Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
                </span>
                </div>
              </v-col>
            </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
        class="py-0"
      >
        <v-btn
          v-if="editMode && item.deliveryAddressId !== 'new_' && (user.rights & availablePermissions[editRight])"
          color="error"
          @click="remove"
          text
        >
          Borrar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editMode"
          @click="cancel"
          text
          title="Cancelar y deshacer cambios (Esc)"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="editMode && (user.rights & availablePermissions[editRight])"
          color="primary"
          @click="save"
          text
          title="Guardar cambios (Ctrl + s)"
        >
          Guardar
        </v-btn>
        <v-btn
          v-if="!editMode && (user.rights & availablePermissions[editRight])"
          color="primary"
          @click="editMode = true"
          text
          title="Editar (F2)"
        >
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '../api'
import EventBus from '@/components/EventBus'
import Vue from 'vue'

export default {
  props: {
    address: Object
  },
  data () {
    return {
      valid: false,
      editMode: false,
      editRight: 'CAN_WRITE',
      item: {},
      user: this.$store.state.user,
      availablePermissions: this.$store.state.availablePermissions,
      isocountrySelect: this.$store.state.isocountrySelect,
      requiredRules: [
        (v) => !!v || '¡Información requerida'
      ]
    }
  },
  computed: {
    deliveryAddressId () {
      return this.address.deliveryAddressId
    }
  },
  mounted () {
    this.init()
    EventBus.$on('ctrlKey_s', (e) => {
      console.log('ctrlKey_s')
      e.preventDefault()
      if (this.editMode) {
        this.save()
      }
    })
    // esc => unselect item
    EventBus.$on('keyEsc', (e) => {
      if (this.editMode) {
        this.cancel()
      }
    })
    EventBus.$on('keyF2', (e) => {
      e.preventDefault()
      this.editMode = true
    })
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_s')
    EventBus.$off('keyEsc')
    EventBus.$off('keyF2')
  },
  methods: {
    // we don't use ItemMixins here since person Object is not obtained from api but through props
    init () {
      this.item = {
        deliveryAddressId: this.address.deliveryAddressId,
        clientId: this.address.clientId,
        deliveryAddressName: this.address.deliveryAddressName,
        company: this.address.company,
        company2: this.address.company2,
        street: this.address.street,
        zipCode: this.address.zipCode,
        city: this.address.city,
        province: this.address.province,
        isoCountry: this.address.isoCountry,
        contact: this.address.contact,
        phone: this.address.phone,
        forwarderComment: this.address.forwarderComment,
        recCreator: this.address.recCreator,
        recCreate: this.address.recCreate,
        recChanger: this.address.recChanger,
        recChange: this.address.recChange
      }
      // open new and unfinished items directly in edit mode
      Vue.nextTick(() => {
        if (!this.$refs.form.validate()) {
          this.editMode = true
        }
      })
    },

    save () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.item.deliveryAddressId === 'new_') {
        api.createItem('addresses', this.item)
          .then(data => {
            this.item = data
            this.editMode = false
            EventBus.$emit('refreshAddresses')
            this.$store.commit('alert',
              {
                show: true,
                text: 'Elemento creado',
                type: 'success'
              }
            )
          })
          .catch(e => {
            this.$store.commit('alert',
              {
                show: true,
                text: e.message,
                type: 'error'
              }
            )
          })
      } else {
        api.updateItem('addresses', this.deliveryAddressId, this.item)
          .then(data => {
            this.item = data
            this.editMode = false
            EventBus.$emit('refreshAddresses')
            this.$store.commit('alert',
              {
                show: true,
                text: 'Elemento actualizado',
                type: 'success'
              }
            )
          })
          .catch(e => {
            this.$store.commit('alert',
              {
                show: true,
                text: e.message,
                type: 'error'
              }
            )
          })
      }
    },
    cancel () {
      // remove unsaved item or reset item
      if (this.address.deliveryAddressId === 'new_') {
        EventBus.$emit('refreshAddresses')
      } else {
        this.init()
      }
    },

    remove () {
      api.removeItem('addresses', this.deliveryAddressId)
        .then(() => {
          this.$store.commit('alert',
            {
              show: true,
              text: 'Elemento borrado',
              type: 'success'
            }
          )
          EventBus.$emit('refreshAddresses')
          Vue.nextTick(() => {
            this.init()
          })
        })
        .catch(e => {
          this.$store.commit('alert',
            {
              show: true,
              text: e.message,
              type: 'error'
            }
          )
        })
    }
  }
}
</script>
