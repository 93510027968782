<template>
  <v-container
    fluid
  >
    <v-row
      class="overflowLayout"
    >
      <v-col
        class="overflowFlex"
      >
        <v-data-table
          style="white-space: nowrap;"
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :footer-props="footerProps"
          :server-items-length="totalItems"
          @update:options="updateQuery()"
          fixed-header
          height="calc(100vh - 130px)"
          mobile-breakpoint="0"
          @click:row="showItem"
          :value="selected"
          single-select
          item-key="clientId"
        >
            <template
              v-slot:[`body.prepend`]="{ headers }"
            >
            <tr
              class="tableFilter"
              py-3
            >
              <td v-for="(header,key) in headers" :key="key" class="pb-1">
                <v-text-field
                  v-if="header.filterType === 'text'"
                  v-model="header.filterValue"
                  hide-details
                  single-line
                  dense
                  clearable
                  @keyup.esc="header.filterValue = ''"
                ></v-text-field>
                <v-select
                  v-else-if="header.filterType === 'select'"
                  flat
                  hide-details
                  dense
                  v-model="header.filterValue"
                  :items="header.selectList"
                ></v-select>
              </td>
            </tr>
          </template>

          <template v-slot:[`footer.prepend`]>
            <v-btn
              v-if="userCanEdit"
              @click="createItem ()"
              title="Crear cliente"
              small
              fab
              color="primary"
              elevation="2"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'

export default {
  mixins: [ItemsMixins],
  data () {
    return {
      editRight: 'CAN_WRITE',
      itemsName: 'clients',
      itemName: 'client',
      headers: [
        {
          text: 'Id',
          align: 'right',
          value: 'clientId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number',
          width: 100
        },
        {
          text: 'Com.',
          value: 'respPers',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('usersSelect', 'userId'),
          filterSearch: 'full'
        },
        {
          text: 'Match',
          value: 'matchcode',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Empresa',
          value: 'company',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Persona',
          value: 'mainName',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Lugar',
          value: 'zip',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'Zona Viti.',
          value: 'region',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('regionSelect', 'value'),
          filterSearch: 'full'
        },
        {
          text: 'Teléfono',
          value: 'mainTel',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Ventas',
          align: 'right',
          value: 'sales12m'
        },
        {
          text: 'Categoría',
          value: 'category',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Tipo',
          value: 'type',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('typeSelect', 'value'),
          filterSearch: 'full'
        }
      ]
    }
  },
  methods: {

    /*
     * all the magic here is done through several methods from ItemsMixins
     */
    defaultOptions () {
      return {
        sortBy: [this.itemName + 'Id'], sortDesc: [false], itemsPerPage: this.savedItemsPerPage(10), page: 1
      }
    },
    showItem (item, props) {
      this.$router.push({
        name: 'client',
        params: {
          id: item.clientId,
          submenu: 'details'
        }
      })
    },
    createItem () {
      this.$router.push({
        name: 'client',
        params: {
          id: 'new_',
          submenu: 'details'
        }
      })
    }
  }
}
</script>
