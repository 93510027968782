<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
  >
    <v-card
      flat
    >
      <v-card-text
        class="pb-0"
      >
        <v-container
          fluid
          pa-0
        >
          <v-row
            dense
          >
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                ref="focussedInput"
                type="text"
                maxlength=50
                v-model="item.company"
                :rules="requiredRules"
                label="Empresa"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=50
                v-model="item.company2"
                label="Empresa2"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              xl="2"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=16
                v-model="item.matchcode"
                :rules="requiredRules"
                label="Matchcode"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              xl="2"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=16
                v-model="item.vatNo"
                label="NIF/RUT/VAT"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              xl="2"
            >
              <v-select
                dense
                :disabled="!editMode || !(user.rights & availablePermissions['CAN_WRITEALL'])"
                persistent-placeholder
                filled
                v-model="item.respPers"
                :rules="requiredRules"
                label="Comercial"
                :items="usersSelect"
                item-text="userId"
                item-value="userId"
              >
              </v-select>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              xl="2"
            >
              <v-select
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                v-model="item.type"
                :rules="typeRules"
                label="Tipo"
                :items="typeSelect"
                item-text="value"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              xl="2"
            >
              <v-select
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                v-model="item.region"
                :rules="regionRules"
                label="Zona vitivinícola"
                :items="regionSelect"
                item-text="value"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col
              cols="6"
              sm="4"
              xl="2"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=50
                v-model="item.category"
                label="Categoría"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row
            dense
          >
            <v-col
              cols="12"
              xl="6"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=50
                v-model="item.street"
                label="Calle"
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
              sm="2"
              xl="1"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=10
                v-model="item.zipCode"
                label="C.P."
              ></v-text-field>
            </v-col>
            <v-col
              cols="8"
              sm="4"
              xl="2"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=25
                v-model="item.city"
                label="Ciudad"
              ></v-text-field>
            </v-col>
            <v-col
              cols="8"
              sm="4"
              xl="2"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=15
                v-model="item.province"
                label="Provincia"
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
              sm="2"
              xl="1"
            >
              <v-select
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                v-model="item.isoCountry"
                :rules="requiredRules"
                label="País"
                :items="isocountrySelect"
                item-text="value"
                item-value="key"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row
            dense
          >
            <v-col
              cols="12"
              sm="12"
            >
              <v-textarea
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                auto-grow
                v-model="item.comments"
                label="Comentarios"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row
            dense
          >
            <v-col
              cols="12"
              sm="12"
            >
              <v-textarea
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                auto-grow
                rows="1"
                v-model="item.production"
                label="Producción"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row
            dense
          >
            <v-col
              cols="12"
              sm="12"
            >
              <v-textarea
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                auto-grow
                rows="1"
                v-model="item.target"
                label="Objetivo"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row
            dense
          >
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                v-model="item.paymentTermId"
                label="Forma pago"
                :items="paymentTerms"
                item-text="paymentTerm"
                item-value="paymentTermId"
              >
              </v-select>
            </v-col>

            <v-col
              cols="6"
              sm="2"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=24
                v-model="item.iban"
                label="IBAN"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              sm="2"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=11
                v-model="item.bic"
                label="BIC"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              sm="4"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                height="26px"
                type="date"
                v-model="item.mandateDate"
                label="Fecha mandato Sepa"
              ></v-text-field>
            </v-col>

            <v-col
              cols="6"
              sm="4"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=50
                v-model="item.invoiceEmail"
                label="Email facturas"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              sm="4"
            >
              <v-select
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                v-model="item.docFormat"
                :rules="requiredRules"
                label="Docs"
                :items="docFormatSelect"
                item-text="value"
                item-value="key"
              >
              </v-select>
            </v-col>

            <v-col
              cols="6"
              sm="4"
            >
              <v-text-field
                dense
                :readonly="!editMode"
                persistent-placeholder
                filled
                type="text"
                maxlength=80
                v-model="item.web"
                label="web"
                @click:append="openLink"
                :append-icon="mdiLinkVariant"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-row
            dense
            class="ma-0"
          >
            <v-col
              cols="12"
            >
              <div
                class="text-caption"
              >
                <span v-if="item.clientId === 'new_'">Pendiente de guardar.</span>
                <span v-else>
                  Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
                </span>
                <span v-if="item.recChanger">
                  Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
                </span>
              </div>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>
      <v-card-actions
        class="py-0"
      >
        <v-btn
          v-if="editMode && item.clientId !== 'new_' && userCanEdit"
          color="error"
          @click="remove"
          text
        >
          Borrar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editMode"
          @click="cancel"
          text
          title="Cancelar y deshacer cambios (Esc)"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="editMode && userCanEdit"
          :loading="isSaving"
          color="primary"
          @click="save"
          text
          title="Guardar cambios (Ctrl + s)"
        >
          Guardar
        </v-btn>
        <v-btn
          v-if="!editMode"
          @click="close"
          text
          title="Volver a lista (Esc)"
        >
          Volver
        </v-btn>
        <v-btn
          v-if="!editMode && userCanEdit"
          color="primary"
          @click="editMode = true"
          text
          title="Editar (F2)"
        >
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-form>
</template>

<script>
import { mdiLinkVariant } from '@mdi/js'
import ItemMixins from '@/utils/ItemMixins'
import EventBus from '@/components/EventBus'

export default {
  mixins: [ItemMixins],
  data () {
    return {
      editRight: 'CAN_WRITE',
      itemName: 'client', // needed for ItemMixins
      itemsName: 'clients', // needed for ItemMixins
      availablePermissions: this.$store.state.availablePermissions,
      mdiLinkVariant: mdiLinkVariant,
      isocountrySelect: this.$store.state.isocountrySelect,
      typeSelect: this.$store.state.typeSelect,
      regionSelect: this.$store.state.regionSelect,
      docFormatSelect: this.$store.state.docFormatSelect,
      paymentTerms: this.$store.state.paymentTerms,
      usersSelect: this.$store.state.usersSelect,
      typeRules: [
        // value instead of key is used and stored in db !!!
        (v) => this.typeSelect.findIndex((e) => e.value === v) !== -1 || '¡Se requiere seleccionar un valor!'
      ],
      regionRules: [
        // value instead of key is used and stored in db !!!
        (v) => this.regionSelect.findIndex((e) => e.value === v) !== -1 || '¡Se requiere seleccionar un valor!'
      ],

      requiredRules: [
        (v) => !!v || '¡Información requerida'
      ]
    }
  },
  watch: {
    clientText: function () {
      EventBus.$emit('dispatchInitApp')
    }
  },
  computed: {
    clientText () {
      return '' + this.item.clientId + this.item.matchcode
    }
  },
  methods: {
    createItem () {
      this.item = {
        clientId: 'new_',
        company: '',
        company2: '',
        matchcode: '',
        vatNo: '',
        respPers: this.$store.state.user.userId,
        type: this.$store.getters.getDefault('type'),
        region: this.$store.getters.getDefault('region'),
        category: '',
        street: '',
        zipCode: '',
        city: '',
        province: '',
        isoCountry: this.$store.getters.getDefault('isocountry'),
        comments: '',
        production: '',
        target: '',
        paymentTermId: null,
        iban: '',
        bic: '',
        mandateDate: null,
        invoiceEmail: '',
        docFormat: 'completo',
        web: ''
      }
      this.editMode = true
    },
    openLink () {
      window.open('https://' + this.item.web)
    }
  }
}
</script>
