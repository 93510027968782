<template>
  <v-data-table
    hide-default-footer
    :dense="inlineItem"
    :headers="displayedHeaders"
    :items="saleItems"
    :options="{itemsPerPage: -1}"
    :class="tableClasses"
    mobile-breakpoint="0"
    style="white-space: nowrap;"
  >
    <template
      v-slot:item="{ item, index }"
    >
      <SaleProductsTableItem
        v-model="saleItems[index]"
        :key="item.salesItemId"
        :saleId="item.saleId"
        :tableEditMode="tableEditMode"
        :saleStatus="saleStatus"
        :inlineItem="inlineItem"
        :saleItems="saleItems"
        :availablePalletIds="availablePalletIds"
        @deleteRow="deleteRow"
      >
      </SaleProductsTableItem>

    </template>
    <template
      v-slot:[`body.append`]="{  }"
    >
      <tr
        v-if="!tableEditMode"
      >
        <!-- paints line under last product -->
      </tr>
      <!-- edit mode -->
      <tr
        v-else
        class="trNoHighlight"
      >
        <td>
          <v-btn
            @click="addRow()"
            title="Añadir producto (Ctrl. + Ins.)"
            x-small
            fab
            elevation="2"
            color="primary"
          >
            <v-icon>{{ mdiPlus }}</v-icon>
          </v-btn>
        </td>
        <td
        >
          Peso: {{ Math.ceil(totalWeight) }} Kg
        </td>

        <td
          class="palletIdColumn"
        >
        </td>
        <td
        >
        </td>

        <td
          v-if="saleStatus !== 'PI'"
        >
          <v-text-field
            class="mt-1"
            dense
            :disabled="!editMode || saleStatus !== 'CO'"
            flat
            reverse
            solo
            type="number"
            :value="freight"
            @change="$emit('update:freight', +$event)"
            suffix="Portes:"
          >
          </v-text-field>
        </td>
        <td
          v-if="saleStatus !== 'PI'"
          class="text-right"
        >{{ totalAmount | toRounded(2) }}{{ ' ' + defaultCurrencySymbol }}</td>
      </tr>
    </template>

  </v-data-table>
</template>

<script>
import SaleProductsTableItem from '@/components/SaleProductsTableItem'
import { mdiPlus } from '@mdi/js'
import api from '../api'
import EventBus from '@/components/EventBus'

export default {
  components: {
    SaleProductsTableItem
  },
  data () {
    return {
      mdiPlus: mdiPlus,
      valid: false,
      availablePalletIds: [],
      headers: [
        {
          text: '',
          value: false,
          sortable: false,
          class: 'firstColumn'
        },
        {
          text: 'Referencia',
          value: 'productId',
          sortable: false
        },
        {
          text: 'Producto',
          value: 'productName',
          class: 'productNameColumn d-none d-md-table-cell',
          sortable: false
        },
        {
          text: 'Palet Id.',
          value: 'palletId',
          class: 'palletIdColumn',
          sortable: false
        },
        {
          text: 'Cantidad',
          align: 'right',
          value: 'qty',
          sortable: false
        },
        {
          text: 'Precio',
          align: 'right',
          value: 'price',
          sortable: false
        },
        {
          text: 'Importe',
          align: 'right',
          // value: 'price',
          sortable: false
        }
      ]
    }
  },
  props: {
    // value represents saleItems passed as model
    value: Array,
    freight: Number,
    saleId: [String, Number],
    editMode: Boolean,
    saleStatus: String,
    inlineItem: Boolean
  },
  computed: {
    saleItems: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    defaultCurrencySymbol () {
      return this.$store.state.defaultCurrencySymbol
    },
    totalWeight () {
      let weight = 0
      for (const el of this.saleItems) {
        if (el.weight) {
          weight += el.qty * el.weight
        }
      }
      return weight
    },
    totalAmount () {
      let amount = 0
      for (const el of this.saleItems) {
        amount += el.qty * el.price
      }
      return amount
    },
    tableEditMode () {
      return this.editMode && (['CO', 'PI']).includes(this.saleStatus)
    },
    tableClasses () {
      let classText = 'elevation-0'
      if (!(['PI', 'AL', 'FA', 'EG']).includes(this.saleStatus)) {
        classText += ' hidePalletId'
      }
      if (this.tableEditMode) {
        classText += ' hideProductNameColumn'
      } else {
        classText += ' hideFirstColumn'
      }
      if (this.inlineItem) {
        classText += ' inlineItem'
      }
      return classText
    },
    displayedHeaders () {
      if (this.saleStatus === 'PI' && this.tableEditMode) {
        return this.headers.slice(0, 5)
      } else {
        return this.headers
      }
    }

  },

  watch: {
    tableEditMode: {
      handler: function (tableEditMode) {
        console.log('tableEditMode', tableEditMode)

        if (tableEditMode && this.saleStatus === 'PI') {
          api.getSaleAvailablePallets(this.saleId)
            .then(data => {
              this.availablePalletIds = data

              // detect total qty required per productId
              const totalQtyLookup = {}
              for (const el of this.saleItems) {
                if (!totalQtyLookup[el.productId]) {
                  totalQtyLookup[el.productId] = el.qty
                } else {
                  totalQtyLookup[el.productId] += el.qty
                }
              }
              // save in salesItems
              for (const el of this.saleItems) {
                el.totalQty = totalQtyLookup[el.productId]
              }

              // stop here if palletIds are assigned already
              for (const el of this.saleItems) {
                // 'offer' is used in old qdb!!!
                if (el.palletId !== '-' && el.palletId !== 'offer') {
                  return
                }
              }

              // automatic assignation of palletIds

              // copy of salesItems completed with palletId
              const saleItems = []

              for (const el of this.saleItems) {
                const availablePalletIds = this.availablePalletIds.filter(item => item.productId === el.productId)

                // control total availability
                let availableQty = 0
                for (const pallet of availablePalletIds) {
                  availableQty += pallet.qty
                }
                if (el.qty > availableQty) {
                  this.$store.commit('alert',
                    {
                      show: true,
                      text: 'Disponibilidad insuficiente: ' + el.productId + ', ' + el.qty + ' vs ' + availableQty,
                      type: 'error'
                    }
                  )
                }

                // assign available PalletIds to saleItems
                let missingQty = el.qty

                // do not assign pallet if not enough availableQty
                if (missingQty > availableQty) {
                  // add new productItems
                  const newRow = {
                    salesItemId: el.salesItemId,
                    saleId: el.saleId,
                    productId: el.productId,
                    productName: el.productName,
                    palletId: '-',
                    showWeight: el.showWeight,
                    weight: el.weight,
                    qty: el.qty,
                    totalQty: el.totalQty,
                    price: el.price
                  }
                  // pending: better add after actual row!!!
                  saleItems.push(newRow)
                  continue
                }

                // search for pallet that matches the size of the order
                for (const pallet of availablePalletIds) {
                  if (pallet.qty === missingQty) {
                    // add new productItems
                    const newRow = {
                      salesItemId: el.salesItemId,
                      saleId: el.saleId,
                      productId: el.productId,
                      productName: el.productName,
                      palletId: pallet.palletId,
                      showWeight: el.showWeight,
                      weight: el.weight,
                      qty: pallet.qty,
                      totalQty: el.totalQty,
                      price: el.price
                    }
                    // pending: better add after actual row!!!
                    saleItems.push(newRow)
                    missingQty = 0
                    break
                  }
                }

                const usedPalletIds = []
                // check again if still missingQty
                if (missingQty !== 0) {
                  for (let i = 0; i < availablePalletIds.length; i++) {
                    // stop early if missing qty fells below a full palletSize
                    if (missingQty < el.palletSize) {
                      break
                    }
                    // use only full pallets
                    if (availablePalletIds[i].qty >= el.palletSize) {
                      const usedQty = missingQty > availablePalletIds[i].qty ? availablePalletIds[i].qty : missingQty
                      const usedPalletId = availablePalletIds[i].palletId
                      // add new productItems
                      const newRow = {
                        // generate random temporal integer id
                        salesItemId: usedPalletIds.length === 0 ? el.salesItemId : Math.round((Math.random() * 1000000000)),
                        saleId: el.saleId,
                        productId: el.productId,
                        productName: el.productName,
                        palletId: usedPalletId,
                        showWeight: el.showWeight,
                        weight: el.weight,
                        qty: usedQty,
                        totalQty: el.totalQty,
                        price: el.price
                      }
                      // pending: better add after actual row!!!
                      saleItems.push(newRow)
                      // this.saleItems.splice(idx + usedPalletIds.length, 0, newRow)
                      missingQty -= usedQty
                      usedPalletIds.push(usedPalletId)
                      // no more missing qty
                      if (missingQty === 0) {
                        break
                      }
                    }
                  }
                }

                // check again if still missingQty
                if (missingQty !== 0) {
                  for (let i = 0; i < availablePalletIds.length; i++) {
                    // now iterate again from the beginning and use less than full pallets
                    if (availablePalletIds[i].qty < el.palletSize) {
                      const usedQty = missingQty > availablePalletIds[i].qty ? availablePalletIds[i].qty : missingQty
                      const usedPalletId = availablePalletIds[i].palletId
                      // add new productItems
                      const newRow = {
                        // generate random temporal integer id
                        salesItemId: usedPalletIds.length === 0 ? el.salesItemId : Math.round((Math.random() * 1000000000)),
                        saleId: el.saleId,
                        productId: el.productId,
                        productName: el.productName,
                        palletId: usedPalletId,
                        showWeight: el.showWeight,
                        weight: el.weight,
                        qty: usedQty,
                        totalQty: el.totalQty,
                        price: el.price
                      }
                      // pending: better add after actual row!!!
                      saleItems.push(newRow)
                      // this.saleItems.splice(idx + usedPalletIds.length, 0, newRow)

                      missingQty -= usedQty
                      usedPalletIds.push(usedPalletId)
                      // no more missing qty
                      if (missingQty === 0) {
                        break
                      }
                    }
                  }
                }
                // check again if still missingQty
                if (missingQty !== 0) {
                  for (let i = 0; i < availablePalletIds.length; i++) {
                    // now use the first unused (full) pallet to complement the order
                    if (!usedPalletIds.includes(availablePalletIds[i].palletId)) {
                      const usedQty = missingQty
                      const usedPalletId = availablePalletIds[i].palletId
                      // add new productItems
                      console.log('add new productItem (use full partially)' + el.productId + ', ' + usedQty + ', ' + usedPalletId)
                      const newRow = {
                        // generate random temporal integer id
                        salesItemId: Math.round((Math.random() * 1000000000)),
                        saleId: el.saleId,
                        productId: el.productId,
                        productName: el.productName,
                        palletId: usedPalletId,
                        showWeight: el.showWeight,
                        weight: el.weight,
                        qty: usedQty,
                        totalQty: el.totalQty,
                        price: el.price
                      }
                      // pending: better add after actual row!!!
                      saleItems.push(newRow)
                      // this.saleItems.splice(idx + usedPalletIds.length, 0, newRow)
                      break
                    }
                  }
                }
              }
              console.log(saleItems)
              console.log(saleItems.length)
              this.saleItems = saleItems
            })
        }
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    }
  },
  mounted () {
    EventBus.$on('ctrlKey_Ins', this.addRow)
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_Ins', this.addRow)
  },
  methods: {
    addRow () {
      if (!this.editMode) {
        return
      }
      const newRow = {
        // generate random temporal integer id
        salesItemId: Math.round((Math.random() * 1000000000)),
        productId: '',
        palletId: '-',
        showWeight: 0,
        weight: 0,
        qty: 0,
        price: 0
      }
      if (this.saleId !== 'new_') {
        newRow.saleId = this.saleId
      }
      this.saleItems.push(newRow)
    },
    deleteRow (salesItemId) {
      this.saleItems = this.saleItems.filter((el) => {
        return '' + el.salesItemId !== '' + salesItemId
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

  .hidePalletId .palletIdColumn {
    display: none !important;
  }
  .hideFirstColumn .firstColumn {
    display: none !important;
  }
  .hideProductNameColumn .productNameColumn {
    display: none !important;
  }

</style>
