<template>
  <v-container
    fluid
  >
    <v-row
    >
      <v-col
      >
        <v-expansion-panels
          v-model="panel"
          accordion
          tile
          dense
        >
          <v-expansion-panel
            v-for="(address, i) in addresses"
            :key="i"
          >
            <v-expansion-panel-header>
              {{ address.deliveryAddressId === 'new_' ? 'Dirección nueva sin guardar' : '' }}
              {{ address.deliveryAddressName }}
              {{ address.deliveryAddressId === 'new_' ? '' : ('(' + address.contact + ', ' + address.street + ', ' + address.zipCode + ' ' + address.city + ', ' + address.province + ', ' + address.isoCountry + ')')}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ClientAddressExpanded
                :address="address"
              >
              </ClientAddressExpanded>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-alert
          v-if="addresses.length === 0"
          type="info"
          color="primary"
          text
        >
          Sin definir direcciones de entrega. Se utilizará la dirección fiscal y el contacto principal como dirección de entrega.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import api from '../api'
import ClientAddressExpanded from '@/components/ClientAddressExpanded'
import EventBus from '@/components/EventBus'

export default {
  components: {
    ClientAddressExpanded
  },
  data () {
    return {
      addresses: [],
      panel: null
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  watch: {
    id: {
      immediate: true,
      handler: 'getItems'
    }
  },
  mounted () {
    EventBus.$on('refreshAddresses', () => {
      console.log('on refresh')
      this.getItems()
    })
    EventBus.$on('ctrlKey_Ins', this.createItem)
  },
  beforeDestroy () {
    // avoid repeated event
    EventBus.$off('refreshAddresses')
    EventBus.$off('ctrlKey_Ins', this.createItem)
  },

  methods: {
    getItems () {
      this.loading = true
      api.getItems('addresses', { clientId: this.id })
        .then(data => {
          this.addresses = data
          this.loading = false
        })
        .catch(e => {
          this.loading = false
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    },
    createItem () {
      const hasUnsavedAddress = this.addresses.find(function (el) {
        return el.deliveryAddressId === 'new_'
      })
      if (hasUnsavedAddress) {
        this.$store.commit('alert',
          {
            show: true,
            text: 'No se puede añadir una dirección nueva. Ya existe una dirección nueva sin guardar.',
            type: 'error'
          }
        )
        return
      }
      this.addresses.push({
        deliveryAddressId: 'new_',
        clientId: this.id,
        deliveryAddressName: null,
        company: null,
        company2: null,
        contact: null,
        phone: null,
        street: null,
        zipCode: null,
        city: null,
        province: null,
        isoCountry: this.$store.getters.getDefault('isocountry'),
        forwarderComment: null
      })
      // open new address expanded
      this.panel = this.addresses.length - 1
    }
  }
}
</script>
