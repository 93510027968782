<template>
  <v-container
    fluid
    style="overflow: auto"
  >
    <v-row
      column
      class="overflowLayout overflowFlex"
    >
      <v-col
      >
        <v-btn
          v-if="userCanEdit && (['Persons', 'Addresses', 'Actions', 'Sales'].includes(moduleName))"
          @click="createItem ()"
          title="Crear elemento (Ctrl. + Ins.)"
          small
          fab
          color="primary"
          elevation="2"
          right
          absolute
          style="top: 30px;"
        >
          <v-icon>{{ mdiPlus }}</v-icon>
        </v-btn>
        <v-card
          flat
        >
          <v-card-title primary-title>
            <h3 class="headline mb-0">
              <span
                v-if="clientId === 'new_'"
              >
                Cliente nuevo
              </span>
              <span
                v-else
              >
                {{ clientText }}
              </span>
            </h3>
          </v-card-title>
          <v-tabs>
            <v-tabs-slider
              color="primary"
            ></v-tabs-slider>
            <v-tab
              router-link
              :to="'/clients/' + clientId + '/details'"
            >
              Detalles
            </v-tab>
            <v-tab
              v-if="clientId !== 'new_'"
              router-link
              :to="'/clients/' + clientId + '/persons'"
            >
              Personas
            </v-tab>
            <v-tab
              v-if="clientId !== 'new_'"
              router-link
              :to="'/clients/' + clientId + '/addresses'"
            >
              Direcciones
            </v-tab>
            <v-tab
              v-if="clientId !== 'new_'"
              router-link
              :to="'/clients/' + clientId + '/actions'"
            >
              Acciones
            </v-tab>
            <v-tab
              v-if="clientId !== 'new_'"
              router-link
              :to="'/clients/' + clientId + '/sales'"
            >
              Ventas
            </v-tab>
          </v-tabs>
          <v-tabs-items>
            <component
              :is="moduleName"
              :ref="submenu"
              :fixedClientId="clientId"
            >
            <!-- component changes when vm.submenu changes! -->
            </component>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Addresses from '@/views/ClientAddresses'
import Details from '@/views/ClientDetails'
import Persons from '@/views/ClientPersons'
import Sales from '@/views/Sales'
import Sale from '@/views/Sale'
import Actions from '@/views/Actions'
import Action from '@/views/Action'

import { mdiPlus } from '@mdi/js'

export default {
  components: {
    Addresses,
    Details,
    Persons,
    Sales,
    Actions,
    Sale,
    Action
  },
  data () {
    return {
      mdiPlus: mdiPlus,
      user: this.$store.state.user,
      availablePermissions: this.$store.state.availablePermissions
    }
  },
  props: {
    submenu: String,
    subId: String
  },
  computed: {
    userCanEdit () {
      // own client
      if (this.client.respPers === this.user.userId) {
        return this.user.rights & this.availablePermissions.CAN_WRITE
      } else {
        return this.user.rights & this.availablePermissions.CAN_WRITEALL
      }
    },
    moduleName () {
      let moduleName = this.submenu.charAt(0).toUpperCase() + this.submenu.slice(1)
      if (this.subId) {
        if (this.submenu === 'sales') {
          moduleName = 'Sale'
        }
        if (this.submenu === 'actions') {
          moduleName = 'Action'
        }
      }
      return moduleName
    },
    clientId () {
      return this.$route.params.id
    },

    client () {
      if (this.clientId === 'new_') {
        return 'new'
      } else {
        const client = this.$store.state.clientsSelect.find((el) => {
          return el.clientId === +this.clientId
        })
        return client || {}
      }
    },
    clientText () {
      if (this.clientId === 'new_') {
        return 'Cliente nuevo'
      } else {
        return (this.client && this.client.search) ? this.client.search : ''
      }
    }
  },
  methods: {
    createItem () {
      console.log(this.submenu)
      this.$refs[this.submenu].createItem()
    }
  }

}
</script>
