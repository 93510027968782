<template>
  <v-container
    fluid
  >
    <v-row
    >
      <v-col
      >
        <v-expansion-panels
          v-model="panel"
          accordion
          tile
          dense
        >
          <v-expansion-panel
            v-for="(person, i) in persons"
            :key="i"
          >
            <v-expansion-panel-header>
              {{ (person.mainPers && !person.firstname && !person.lastname) ? 'Persona principal con datos incompletos' : '' }}
              {{ person.personId === 'new_' ? 'Persona nueva sin guardar' : '' }}

              {{
                (person.salutation && (person.firstname || person.lastname)) ? person.salutation + ' ' : ''
              }}{{
                person.firstname ? person.firstname + ' ' : ''
              }}{{
                person.lastname
              }}{{
                person.position ? ' (' + person.position + ')' : ''
              }}{{
                 person.tel1Number ? ', Tel. +' + person.tel1Country + '-' + person.tel1Number : ''
              }}{{
                person.tel2Number ? ', Tel. +' + person.tel2Country + '-' + person.tel2Number : ''
              }}{{
                person.email ? ', ' + person.email : ''
              }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ClientPersonExpanded
                :person="person"
              >
              </ClientPersonExpanded>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import api from '../api'
import ClientPersonExpanded from '@/components/ClientPersonExpanded'
import EventBus from '@/components/EventBus'

export default {
  components: {
    ClientPersonExpanded
  },
  data () {
    return {
      persons: [],
      panel: null
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  watch: {
    id: {
      immediate: true,
      handler: 'getItems'
    }
  },
  mounted () {
    EventBus.$on('refreshPersons', () => {
      console.log('on refresh')
      this.getItems()
    })
    EventBus.$on('ctrlKey_Ins', this.createItem)
  },
  beforeDestroy () {
    // avoid repeated event
    EventBus.$off('refreshPersons')
    EventBus.$off('ctrlKey_Ins', this.createItem)
  },

  methods: {
    getItems () {
      this.loading = true
      api.getItems('persons', { clientId: this.id })
        .then(data => {
          this.persons = data
          this.loading = false
          // open mainPerson expanded if incomplete
          const mainPerson = this.persons[0]
          if (mainPerson && !mainPerson.firstname && !mainPerson.lastname) {
            this.panel = 0
          }
        })
        .catch(e => {
          this.loading = false
          this.$store.commit('alert',
            {
              show: true,
              text: 'Error al cargar datos!',
              type: 'error'
            }
          )
        })
    },
    createItem () {
      const hasUnsavedPerson = this.persons.find(function (el) {
        return el.personId === 'new_'
      })
      if (hasUnsavedPerson) {
        this.$store.commit('alert',
          {
            show: true,
            text: 'No se puede añadir una persona nueva. Ya existe una persona nueva sin guardar.',
            type: 'error'
          }
        )
        return
      }
      this.persons.push({
        personId: 'new_',
        clientId: this.id,
        salutation: null,
        firstname: null,
        lastname: null,
        mainPers: 0,
        position: null,
        tel1Country: null,
        tel1Number: null,
        tel2Country: null,
        tel2Number: null,
        fax1Country: null,
        fax1Number: null,
        email: null,
        tags: null,
        marketing: this.$store.getters.getDefault('marketing'),
        gift: this.$store.getters.getDefault('gift'),
        persComment: null
      })
      // open new person expanded
      this.panel = this.persons.length - 1
      console.log(this.$store.getters.getDefault('marketing'))
    }
  }
}
</script>
