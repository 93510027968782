<template>
  <v-container
    fluid
    style="overflow: auto"
    pa-0
  >
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-card
        :elevation="inlineItem ? '1' : '0'"
        :class="inlineItem ? 'inlineItem' : ''"
        tile
      >
        <v-card-text
          class="pb-0"
        >
          <v-container
            v-if="item.saleId"
            fluid
            :class="inlineItem ? 'pa-0' : 'pa-2'"
            :style="inlineItem ? 'min-height: 400px;' : ''"
          >
            <!-- client -->
            <v-row
              v-if="!editMode || item.saleStatus !== 'CO'"
              dense
            >
              <v-col
                cols="12"
              >
                <div>
                  <b>
                    {{ item.saleStatus === 'CR' ? 'Cotización rechazada' : '' }}
                    {{ item.saleStatus === 'CO' ? 'Cotización' : '' }}
                    {{ item.saleStatus === 'PE' ? 'Pedido' : '' }}
                    {{ item.saleStatus === 'PI' ? 'Picking' : '' }}
                    {{ item.saleStatus === 'AL' ? deliveryDocDisplay : '' }}
                    {{ item.saleStatus === 'FA' && !item.cn ? 'Factura' : '' }}
                    {{ item.saleStatus === 'FA' && item.cn ? 'Nota de crédito' : '' }}
                    {{ item.saleStatus === 'EG' ? 'Entrega gratuita' : '' }}
                    <span
                      v-if="(['CR']).includes(item.saleStatus)"
                    >
                      {{ 'CR' + ('0000' + item.saleId).substr(-4) }}
                    </span>
                    <span
                      v-if="!item.cn && ((['PE', 'PI', 'AL', 'FA', 'EG']).includes(item.saleStatus) || (item.saleStatus === 'CO' && !editMode))"
                    >
                      <a
                        title="Abrir proforma pdf"
                        target="_blank"
                        :href="apiHost + '/api/pdfdocs/proforma_' + ('0000' + item.saleId).substr(-4)  + '.pdf'"
                      >{{ 'CO' + ('0000' + item.saleId).substr(-4) }}</a>
                    </span>
                    <span
                      v-if="!item.cn && ((['AL', 'FA', 'EG']).includes(item.saleStatus) || (item.saleStatus === 'PI' && !editMode))"
                    > /
                      <a
                        :title="'Abrir packing list pdf'"
                        target="_blank"
                        :href="apiHost + '/api/pdfdocs/packing_list_' + ('0000' + item.saleId).substr(-4) + '.pdf'"
                      >{{ 'PI' + ('0000' + item.saleId).substr(-4) }}</a>
                    </span>
                    <span
                      v-if="!item.cn && item.saleStatus === 'PI' && editMode"
                    > /
                      {{ 'PI' + ('0000' + item.saleId).substr(-4) }}
                    </span>

                    <span
                      v-if="!item.cn && ((['FA', 'EG']).includes(item.saleStatus) || (item.saleStatus === 'AL' && !editMode))"
                    > /
                      <a
                        :title="'Abrir ' + deliveryDocDisplay + ' pdf'"
                        target="_blank"
                        :href="apiHost + '/api/pdfdocs/' + deliveryDocFile + '_' + ('0000' + item.shipNo).substr(-4) + '.pdf'"
                      >{{ 'AL' + ('0000' + item.shipNo).substr(-4) }}</a>
                    </span>
                    <span
                      v-if="!item.cn && item.saleStatus === 'AL' && editMode && item.shipNo"
                    > /
                      {{ 'AL' + ('0000' + item.shipNo).substr(-4) }}
                    </span>
                    <span
                      v-if="!item.cn && item.saleStatus === 'FA' && !editMode"
                    > /
                    </span>
                    <span
                      v-if="item.saleStatus === 'FA' && !editMode"
                    >
                      <a
                        :title="'Abrir Factura pdf'"
                        target="_blank"
                        :href="apiHost + '/api/pdfdocs/factura_' + invoiceSerieString + ('0000' + Math.abs(item.invoiceNo)).substr(-4) + '.pdf'"
                      >{{ 'FA' + (item.invoiceSerie || '') + ('0000' + Math.abs(item.invoiceNo)).substr(-4) }}</a>
                    </span>
                    <span
                      v-if="!item.cn && item.saleStatus === 'FA' && editMode && item.invoiceNo"
                    > /
                    </span>
                    <span
                      v-if="item.saleStatus === 'FA' && editMode && item.invoiceNo"
                    >
                      {{ 'FA' + (item.invoiceSerie || '') + ('0000' + Math.abs(item.invoiceNo)).substr(-4) }}
                    </span>
                    del {{ item.saleDate }}.
                    <a
                      title="Ir al cliente"
                      :href="'/#/clients/' + item.clientId + '/details'"
                    >{{ item.matchcode}}</a>,
                    <a
                      title="Ir a personas del cliente"
                      :href="'/#/clients/' + item.clientId + '/persons'"
                    >{{ item.firstname }} {{ item.lastname }}</a>
                  </b>
                </div>
              </v-col>
            </v-row>

            <v-row
              v-else
              dense
            >
              <v-col
                cols="12"
                sm="4"
              >
                <v-autocomplete
                  dense
                  :disabled="!editMode || item.saleStatus !== 'CO' || !!fixedClientId"
                  auto-select-first
                  persistent-placeholder
                  filled
                  ref="focussedInput"
                  v-model="item.clientId"
                  :rules="clientIdRules"
                  :items="clientsSelect"
                  item-text="search"
                  item-value="clientId"
                  label="Cliente *"
                  @change="item.aliasClientId = item.clientId"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-autocomplete
                  dense
                  auto-select-first
                  persistent-placeholder
                  filled
                  v-model="item.aliasClientId"
                  :rules="clientIdRules"
                  :items="clientsSelect"
                  item-text="search"
                  item-value="clientId"
                  label="Cliente Alias *"
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  dense
                  :disabled="!editMode || item.saleStatus !== 'CO'"
                  persistent-placeholder
                  filled
                  v-model="item.personId"
                  label="Contacto pedido"
                  :items="personsSelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>
            </v-row>

            <!-- products -->
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <SaleProductsTable
                  v-if="item.saleItems && !(editMode && (['PE', 'AL', 'FA']).includes(item.saleStatus)) || item.cn"
                  v-model="item.saleItems"
                  :freight.sync="item.freight"
                  :saleId="item.saleId"
                  :editMode="editMode"
                  :saleStatus="item.saleStatus"
                  :inlineItem="inlineItem"
                >
                </SaleProductsTable>
              </v-col>
            </v-row>

            <!-- other CO data -->
            <v-row
              v-if="item.cn || !editMode"
              dense
            >
              <v-col
                cols="12"
                sm="6"
              >
                <p
                >
                  Peso neto: {{ Math.ceil(totalWeight) }} Kg
                  <span
                    v-if="item.paymentTermId"
                  >
                    <br>Forma de pago: {{ paymentTerms.find(el => el.paymentTermId === item.paymentTermId).paymentTerm }}
                  </span>
                  <span
                    v-if="item.offerComment"
                  >
                    <br>Texto en cotización: {{ item.offerComment }}
                  </span>
                  <span
                    v-if="item.offerRejectComment"
                  >
                    <br>Razón rechazo: {{ item.offerRejectComment }}
                  </span>
                  <span
                    v-if="item.shipComment"
                  >
                    <br>Texto en albarán: {{ item.shipComment }}
                  </span>
                  <span
                    v-if="item.invoiceComment && !editMode"
                  >
                    <br>Texto en factura: {{ item.invoiceComment }}
                  </span>
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-row>
                  <v-col
                  >
                    <div class="text-right">Neto (sin portes):</div>
                    <div class="text-right">Portes:</div>
                    <div class="text-right">Total neto:</div>
                    <div class="text-right">Total total con IVA {{ item.vat * 100 }}%:</div>
                  </v-col>
                  <v-col
                  cols="auto"
                  >
                    <div class="text-right">{{ totalAmount | toRounded(2) }}{{ ' ' + defaultCurrencySymbol }}</div>
                    <div class="text-right">{{ item.freight | toRounded(2) }}{{ ' ' + defaultCurrencySymbol }}</div>
                    <div class="text-right">{{ (totalAmount + item.freight) | toRounded(2) }}{{ ' ' + defaultCurrencySymbol }}</div>
                    <div class="text-right">{{ ((1 + item.vat) * (totalAmount + item.freight)) | toRounded(2) }}{{ ' ' + defaultCurrencySymbol }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              v-if="editMode && item.saleStatus === 'CO'"
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :disabled="!editMode || item.saleStatus !== 'CO'"
                  persistent-placeholder
                  filled
                  rows="2"
                  v-model="item.offerComment"
                  label="Texto en cotización"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- CR data -->
            <v-row
              v-if="editMode && item.saleStatus==='CR'"
              dense
            >
              <v-col
                cols="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  rows="2"
                  v-model="item.offerRejectComment"
                  label="Razones para rechazo de cotizacón"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- PE data -->
            <v-row
              v-if="!editMode && !item.cn && (['PE', 'PI', 'AL', 'FA']).includes(item.saleStatus)"
              dense
            >
              <v-col
                cols="12"
                sm="6"
              >
              <v-container
                py-0
              >
                <v-row>
                  <v-col
                    cols="4"
                    class="text-right"
                  >
                    Dirección de entrega:
                  </v-col>
                  <v-col
                    cols="8"
                  >
                    {{ item.shipToCompany }}
                    {{ item.shipToCompany2 }},
                    {{ item.shipToContact }} (Tel. {{ item.shipToPhone }})
                    {{ item.shipToStreet }}
                    {{ item.shipToZipCode }}
                    {{ item.shipToCity }},
                    {{ item.shipToProvince }},
                    {{ item.shipToIsoCountry }}
                    {{ item.forwarderComment ? ' (' + item.forwarderComment + ')' : '' }}
                  </v-col>
                </v-row>
                <v-row
                  v-if="item.orderComment"
                  class="py-0"
                >
                  <v-col
                    cols="4"
                    class="py-0 text-right"
                  >
                    Confirmación pedido:
                  </v-col>
                  <v-col
                    cols="8"
                    class="py-0"
                  >
                    {{ item.orderComment }}
                  </v-col>
                </v-row>
                <v-row
                    v-if="item.orderInstruction"
                    class="py-0"
                >
                  <v-col
                    cols="4"
                    class="py-0 text-right"
                  >
                    Instrucciones pedido:
                  </v-col>
                  <v-col
                    cols="8"
                    class="py-0"
                  >
                    {{ item.orderInstruction }}
                  </v-col>
                </v-row>
                <v-row
                    v-if="item.pickComment"
                    class="py-0"
                >
                  <v-col
                    cols="4"
                    class="py-0 text-right"
                  >
                    Instrucciones picking:
                  </v-col>
                  <v-col
                    cols="8"
                    class="py-0"
                  >
                    {{ item.pickComment }}
                  </v-col>
                </v-row>
                <v-row
                    v-if="item.parcels"
                    class="py-0"
                >
                  <v-col
                    cols="4"
                    class="py-0 text-right"
                  >
                    Bultos:
                  </v-col>
                  <v-col
                    cols="8"
                    class="py-0"
                  >
                    {{ item.parcels }}
                  </v-col>
                </v-row>
              </v-container>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-row>
                  <v-col
                    cols="auto"
                  >
                    <div class="text-right">Ref cliente:</div>
                    <div class="text-right">Fecha entrega:</div>
                    <div class="text-right">Formato albarán:</div>
                    <div class="text-right">Transportista:</div>
                    <div
                      v-if="item.forwarderTrackingId || item.forwarderBookingId"
                      class="text-right"
                    >
                      Tracking:
                    </div>
                  </v-col>
                  <v-col
                  >
                    <div>{{ item.orderRef || '-'}}</div>
                    <div>{{ item.orderRequiredDate || 'ASAP'}}</div>
                    <div>{{ item.docFormat }}</div>
                    <div>{{ item.forwarderName || '-' }}</div>
                    <div
                      v-if="item.forwarderTrackingId || item.forwarderBookingId"
                    >
                      <span
                        v-if="item.forwarderTrackingId"
                      >
                        <!-- SCHENKER --><!-- STT -->
                        <a
                          v-if="item.forwarderIntegration === 'schenker'"
                          target="_blank"
                          title="Ver situación en web de SCHENKER"
                          :href="'https://eschenker.dbschenker.com/app/nges-portal/tracking/schenker-search?language_region=es-ES_ES&refNumber='  + item.forwarderTrackingId"
                        >
                          {{ item.forwarderTrackingId }}
                        </a>
                        <!-- SEUR -->
                        <a
                          v-else-if="item.forwarderIntegration === 'seur'"
                          target="_blank"
                          title="Ver situación en web de SEUR"
                          :href="'https://www.seur.com/livetracking/?segOnlineIdentificador=' + item.forwarderTrackingId"
                        >
                          {{ item.forwarderTrackingId }}
                        </a>
                        <!-- DHL -->
                        <a
                          v-else-if="item.forwarderIntegration === 'dhl'"
                          target="_blank"
                          title="Ver situación en web de DHL"
                          :href="'https://www.dhl.com/es-es/home/tracking/tracking-express.html?submit=1&tracking-id=' + item.forwarderTrackingId"
                        >
                          {{ item.forwarderTrackingId }}
                        </a>
                      </span>
                      <span
                        v-else-if="item.forwarderBookingId"
                      >
                        <!-- SCHENKER --><!-- BookingId -->
                        <a
                          v-if="item.forwarderIntegration === 'schenker'"
                          target="_blank"
                          title="Ver situación en web de SCHENKER"
                          :href="'https://eschenker.dbschenker.com/app/nges-portal/tracking/customer-search?refType=BID&timeSelectionMode=PAST_4_WEEKS&language_region=es-ES_ES&refNumber=' + item.forwarderBookingId"
                        >
                          {{ item.forwarderBookingId }}
                        </a>
                        <!-- SEUR -->
                        <a
                          v-else-if="item.forwarderIntegration === 'seur'"
                          target="_blank"
                          title="Ver situación en web de SEUR"
                          :href="'https://www.seur.com/livetracking/?segOnlineIdentificador=' + item.forwarderBookingId"
                        >
                          {{ item.forwarderBookingId }}
                        </a>
                      </span>
                      <v-icon small>{{ mdiOpenInNew }}</v-icon>
                      ({{ item.shipStatus }} {{ item.shipStatusEventDate | toLocalDate }})
                    </div>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>

            <v-row
              v-if="editMode && item.saleStatus==='PE'"
              dense
            >
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  dense
                  outlined
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="deliveryAddress"
                  placeholder="Direcciones de entrega predefinidas"
                  :items="addressesSelect"
                  @change="setDeliveryAddress"
                >
                </v-select>
              </v-col>
              <v-col
                cols="0"
                sm="6"
              >
              </v-col>
              <v-col
                cols="12"
                sm="6"
                xl="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.shipToCompany"
                  :rules="requiredRules"
                  label="Empresa"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                xl="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.shipToCompany2"
                  label="Empresa (2da línea)"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                xl="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.shipToContact"
                  :rules="requiredRules"
                  label="Contacto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                xl="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=20
                  v-model="item.shipToPhone"
                  :rules="requiredRules"
                  label="Teléfono de contacto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                xl="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.shipToStreet"
                  :rules="requiredRules"
                  label="Calle"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
                xl="1"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=10
                  v-model="item.shipToZipCode"
                  :rules="requiredRules"
                  label="C.P."
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="4"
                xl="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=25
                  v-model="item.shipToCity"
                  :rules="requiredRules"
                  label="Ciudad"
                ></v-text-field>
              </v-col>
              <v-col
                cols="8"
                sm="4"
                xl="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=15
                  v-model="item.shipToProvince"
                  :rules="requiredRules"
                  label="Provincia"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="2"
                xl="1"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.shipToIsoCountry"
                  :rules="requiredRules"
                  label="País"
                  :items="isocountrySelect"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                xl="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=120
                  v-model="item.forwarderComment"
                  label="Instrucciones para el transportista"
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="3"
                xl="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  filled
                  height="26px"
                  type="date"
                  :rules="orderRequiredDateRules"
                  v-model="item.orderRequiredDate"
                  label="Entrega deseada"
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="3"
                xl="2"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.forwarderId"
                  :rules="requiredRules"
                  label="Transportista"
                  :items="forwardersSelect"
                  item-text="forwarderName"
                  item-value="forwarderId"
                >
                </v-select>
              </v-col>

              <v-col
                cols="6"
                sm="3"
                xl="1"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.docFormat"
                  label="Formato albarán"
                  :items="['completo', 'abreviado']"
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                sm="3"
                xl="1"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=20
                  v-model="item.orderRef"
                  label="Ref. Cliente"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                xl="6"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  rows="2"
                  v-model="item.orderComment"
                  label="Texto en confirmación pedido"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                xl="6"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  rows="2"
                  v-model="item.orderInstruction"
                  label="Instrucciones pedido (interno)"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- PI data -->
            <v-row
              v-if="!editMode || (['PI', 'AL', 'FA']).includes(item.saleStatus)"
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >

              </v-col>
            </v-row>

            <v-row
              v-if="editMode && item.saleStatus==='PI'"
              dense
            >
              <v-col
                cols="12"
                sm="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="number"
                  v-model="item.parcels"
                  label="Bultos"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  rows="2"
                  v-model="item.pickComment"
                  label="Instrucciones picking"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- AL data -->
            <v-row
              v-if="!editMode || (['AL', 'FA']).includes(item.saleStatus)"
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >

              </v-col>
            </v-row>

            <v-row
              v-if="editMode && item.saleStatus==='AL'"
              dense
            >
              <v-col
                cols="6"
                md="3"
              >
                <v-text-field
                  ref="focussedInput"
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=6
                  v-model="item.shipNo"
                  :rules="requiredRules"
                  :label="'Nº ' + deliveryDocDisplay"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="date"
                  v-model="item.shipDate"
                  :rules="requiredRules"
                  :label="'Fecha ' + deliveryDocDisplay"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  v-model="item.forwarderId"
                  :rules="requiredRules"
                  label="Transportista"
                  :items="forwardersSelect"
                  item-text="forwarderName"
                  item-value="forwarderId"
                >
                </v-select>
              </v-col>

              <v-col
                cols="6"
                md="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="number"
                  v-model="item.parcels"
                  :rules="requiredRules"
                  label="Bultos"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  rows="3"
                  v-model="item.shipComment"
                  :label="'Texto en ' + deliveryDocDisplay"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- FA data -->
            <v-row
              v-if="!editMode && item.saleStatus === 'FA'"
              dense
            >
              <v-col
                cols="12"
                sm="6"
              >
              </v-col>
            </v-row>
            <v-row
              v-if="editMode && item.saleStatus === 'FA'"
              dense
            >
              <v-col
                v-if="invoiceSeriesSelect"
                cols="12"
                :sm="invoiceSeriesSelect ? '4' : '6'"
              >
                <v-select
                  ref="focussedInput"
                  dense
                  :readonly="!editMode || !!item.cn"
                  persistent-placeholder
                  filled
                  v-model="item.invoiceSerie"
                  label="Serie factura"
                  :items="[{value: 'normal', key: null }, ...invoiceSeriesSelect]"
                  item-text="value"
                  item-value="key"
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                :sm="invoiceSeriesSelect ? '4' : '6'"
              >
                <v-text-field
                  ref="focussedInput"
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=6
                  v-model="item.invoiceNo"
                  :rules="invoiceNoRules"
                  label="Nº factura"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                :sm="invoiceSeriesSelect ? '4' : '6'"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="date"
                  v-model="item.invoiceDate"
                  :rules="requiredRules"
                  label="Fecha factura"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  rows="3"
                  v-model="item.invoiceComment"
                  label="Texto en factura"
                ></v-textarea>
              </v-col>

            </v-row>
          </v-container>
          <!-- footer -->
          <div
            class="text-caption"
          >
            <span v-if="item.saleId === 'new_'">Pendiente de guardar.</span>
            <span v-else>
              Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
            </span>
            <span v-if="item.recChanger">
              Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
            </span>
          </div>

        </v-card-text>
        <v-card-actions
          class="pt-0"
        >
          <v-btn
            v-if="editMode && item.saleId !== 'new_' && userCanEdit && (['CO']).includes(item.saleStatus)"
            color="error"
            @click="remove"
            text
          >
            Borrar
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit && item.saleStatus === 'CO'"
            color="error"
            @click="editSale('CR')"
            text
            title="Mover a cotización rechazada (CR)"
          >
            Mover a CR
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit && item.saleStatus === 'PE'"
            color="error"
            @click="editSale('CO')"
            text
            title="Retroceder a cotización (CO)"
          >
            Retroceder a CO
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit && userCan('CAN_PICK') && item.saleStatus === 'PI'"
            color="error"
            @click="editSale('PE')"
            text
            title="Retroceder a pedido (PE)"
          >
            Retroceder a PE
          </v-btn>
          <v-btn
            v-if="!editMode && userCan('CAN_PICK') && userCan('CAN_SHIP') && item.saleStatus === 'AL' && !item.blocked"
            color="error"
            @click="editSale('PI')"
            text
            title="Retroceder a picking (PI)"
          >
            Retroceder a PI
          </v-btn>
          <v-btn
            v-if="!editMode && userCan('CAN_SHIP') && userCan('CAN_INVOICE') && item.saleStatus === 'FA' && !item.blocked && !item.cn"
            color="error"
            @click="editSale('AL')"
            text
            title="Retroceder a albarán (AL)"
          >
            Retroceder a AL
          </v-btn>
          <v-btn
            v-if="!editMode && userCan('CAN_SHIP') && userCan('CAN_INVOICE') && item.saleStatus === 'FA' && item.blocked && !item.cn"
            color="error"
            @click="gotoCreateItem(item.saleId)"
            text
            title="Crear nota de crédito / factura rectificativa (CN)"
          >
            Crear nota de crédito
          </v-btn>
          <fileUpload
            v-if="!deliveryDocCreation && editMode && item.saleStatus === 'FA'"
            inputName="fileupload"
            uploadPath="/api/pdfdocs/deliverynote"
            :data="{saleId: item.saleId}"
            :btnText="'Subir ' + deliveryDocDisplay"
            :btnTitle="'Subir '  + deliveryDocDisplay + ' (se guarda directamente)'"
            btnColor="primary"
            :multiple="false"
            acceptFileType="application/pdf"
          >
          </fileUpload>
          <fileUpload
            v-if="!invoiceDocCreation && editMode && item.saleStatus === 'FA'"
            inputName="fileupload"
            uploadPath="/api/pdfdocs/invoice"
            :data="{saleId: item.saleId}"
            btnText="Subir factura"
            btnTitle="Subir factura (se guarda directamente)"
            btnColor="primary"
            :multiple="false"
            acceptFileType="application/pdf"
          >
          </fileUpload>

          <v-spacer></v-spacer>
          <v-btn
            v-if="editMode"
            @click="cancel"
            text
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="editMode && userCanEdit"
            :loading="isSaving"
            color="primary"
            @click="save"
            text
            title="Guardar cambios (Ctrl + s)"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="!editMode"
            @click="close"
            text
            :title="inlineItem ? 'Cerrar (Esc)' : 'Volver a lista (Esc)'"
          >
            {{ inlineItem ? 'Cerrar' :  'Volver' }}
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit"
            :disabled="item.blocked"
            color="primary"
            @click="editSale(item.saleStatus)"
            text
          >
            Editar
          </v-btn>
          <v-btn
            v-if="!editMode && userCanEdit && item.saleStatus === 'CO'"
            color="primary"
            @click="editSale('PE')"
            text
            title="Convertir a pedido (PE)"
          >
            Convertir a PE
          </v-btn>
          <v-btn
            v-if="!editMode && userCan('CAN_PICK') && item.saleStatus === 'PE'"
            :disabled="!availability"
            color="primary"
            @click="editSale('PI')"
            text
            title="Convertir a picking (PI)"
          >
            Convertir a PI
          </v-btn>
          <v-btn
            v-if="!editMode && userCan('CAN_SHIP') && item.saleStatus === 'PI'"
            color="primary"
            @click="editSale('AL')"
            text
            title="Convertir a albarán (AL)"
          >
            Convertir a AL
          </v-btn>
          <v-btn
            v-if="!editMode && userCan('CAN_INVOICE') && item.saleStatus === 'AL' && (item.sales !== 0 || item.freight !== 0)"
            :disabled="!item.firstShipNo"
            color="primary"
            @click="editSale('FA')"
            text
            title="Convertir a factura (FA)"
          >
            Convertir a FA
          </v-btn>
          <v-btn
            v-if="!editMode && userCan('CAN_INVOICE') && item.saleStatus === 'AL' && item.sales === 0 && item.freight === 0"
            color="primary"
            @click="editSale('EG')"
            text
            title="Convertir a entrega gratuita (EG)"
          >
            Convertir a EG
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import SaleProductsTable from '@/components/SaleProductsTable'
import FileUpload from '@/components/FileUpload'

import { mdiFilePdfBox, mdiOpenInNew } from '@mdi/js'
import ItemMixins from '@/utils/ItemMixins'
import api from '../api'

export default {
  mixins: [ItemMixins],
  components: {
    SaleProductsTable,
    FileUpload
  },
  props: {
    // value represents selectedItemId
    value: [String, Number],
    fixedClientId: String,
    inlineItem: Boolean
  },
  data () {
    return {
      editRight: 'CAN_WRITE',
      itemsName: 'sales', // needed for ItemMixins
      itemName: 'sale', // needed for ItemMixins
      apiHost: this.$store.state.apiHost,
      mdiFilePdfBox: mdiFilePdfBox,
      mdiOpenInNew: mdiOpenInNew,
      defaultCurrency: this.$store.state.defaultCurrency,
      defaultCurrencySymbol: this.$store.state.defaultCurrencySymbol,
      isocountrySelect: this.$store.state.isocountrySelect,
      forwardersSelect: this.$store.state.forwardersSelect,
      invoiceSeriesSelect: this.$store.state.invoiceSeriesSelect,
      paymentTerms: this.$store.state.paymentTerms,
      deliveryDocFile: this.$store.state.deliveryDocFile || 'albaran',
      deliveryDocDisplay: this.$store.state.deliveryDocDisplay || 'Albarán',
      deliveryDocCreation: this.$store.state.deliveryDocCreation,
      invoiceDocCreation: this.$store.state.invoiceDocCreation,
      invoiceDocCnText: this.$store.state.invoiceDocCnText || false,

      personsSelect: [],
      addressesSelect: [],
      deliveryAddress: null,
      clientIdRules: [
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Falta elegir un cliente'
      ],
      parcelsRules: [
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Debe ser 0 o un número positivo'
      ],
      freightRules: [
        //  decimal
        (v) => /^-?\d*\.{0,2}\d+$/.test(v) || 'Debe ser un número'
      ],
      invoiceNoRules: [
        // positive integer including 0
        (v) => /^\d+$/.test(v) || 'Debe ser un número positivo'
      ],
      orderRequiredDateRules: [
        // date must be at least 3 days ahead
        (v) => v === null || v === '' || new Date(v) > new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000)) || 'Dejar vacío para ASAP'
      ],
      requiredRules: [
        (v) => !!v || '¡Información requerida'
      ]

    }
  },
  watch: {
    'item.clientId': {
      handler: function (val) {
        if (this.editMode && this.item.saleStatus === 'CO') {
          this.getClientPersonsSelect(val)
        }
      },
      // the callback will be called immediately after the start of the observation
      immediate: false
    },
    // overwrite editMode watch from ItemMixins!
    editMode: {
      handler: function (editMode) {
        if (editMode === true) {
          if (this.item.saleStatus === 'CO') {
            this.getClientPersonsSelect(this.item.clientId)
          }
          if (this.item.saleStatus === 'PE') {
            this.getClientAddressesSelect(this.item.clientId)
          }
          if (this.item.saleStatus === 'AL' && !this.item.shipNo) {
            this.getLastShipNo(this.item.saleId)
          }
          if (this.item.saleStatus === 'FA' && !this.item.invoiceNo) {
            this.getLastInvoiceNo(this.item.saleId, this.item.invoiceSerie)
          }

          // focus first input when entering editMode
          this.$nextTick(function () {
            if (this.$refs.focussedInput) {
              this.$refs.focussedInput.focus()
            } else {
              console.log('missing focussedInput')
            }
          })
        }
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    },
    'item.invoiceSerie': {
      handler: function (val) {
        if (this.editMode && this.item.saleStatus === 'FA') {
          console.log('handler item.invoiceSerie', this.item.saleId)
          this.item.invoiceNo = null
          this.getLastInvoiceNo(this.item.saleId, val)
        }
      },
      // the callback will be called immediately after the start of the observation
      immediate: false
    }

  },
  computed: {
    invoiceSerieString () {
      console.log('this.invoiceSeriesSelect', this.invoiceSeriesSelect)
      if (!this.invoiceSeriesSelect) {
        return ''
      }
      const tmp = this.invoiceSeriesSelect.find(el => el.key === this.item.invoiceSerie)
      return (tmp && tmp.value) ? tmp.value + '_' : ''
    },
    // computed to respond to added clients
    clientsSelect () {
      return this.$store.state.clientsSelect
    },

    totalWeight () {
      let weight = 0
      for (const el of this.item.saleItems) {
        weight += el.qty * el.weight
      }
      return weight
    },
    totalAmount () {
      let amount = 0
      for (const el of this.item.saleItems) {
        amount += el.qty * el.price
      }
      return amount
    },
    availability () {
      for (const el of this.item.saleItems) {
        if (el.qtyAvailable < el.qty) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    editSale (saleStatus) {
      this.editMode = true
      this.item.saleStatus = saleStatus
      this.deliveryAddress = null

      if (saleStatus === 'PE' && !this.item.forwarderId) {
        // set default forwarder
        const forwarderId = parseInt(this.$store.getters.getDefault('forwarderId'))
        if (forwarderId) {
          this.item.forwarderId = forwarderId
        }
      }
    },
    gotoCreateItem (cn) {
      // change of saleId / modelItemId will trigger watch in ItemMixins and then execute createItem
      this.item.cn = cn
      this.item.saleId = 'new_'
      this.modelItemId = 'new_'
    },

    createItem (cn) {
      if (cn) {
        console.log('createItem sale cn')
        api.getItem('sales', cn)
          .then(data => {
            const item = data
            // create/post new item with cn === original saleId indicates credit note
            item.cn = item.saleId
            item.saleId = 'new_'
            // invert amounts
            for (const el of item.saleItems) {
              el.qty = -el.qty
            }
            item.freight = -item.freight

            // preset default CN text
            let invoiceDocCnText = this.invoiceDocCnText
            if (invoiceDocCnText) {
              invoiceDocCnText = invoiceDocCnText.replace('{shipNo}', item.shipNo)
              invoiceDocCnText = invoiceDocCnText.replace('{invoiceNo}', item.invoiceNo)
              invoiceDocCnText = invoiceDocCnText.replace('{invoiceSerie}', (item.invoiceSerie || ''))
            }
            item.invoiceComment = invoiceDocCnText || ''

            // special invoice serie 'CN' is used for credit notes (as opposed to use normal invoice counter)
            if (this.invoiceSeriesSelect && this.invoiceSeriesSelect.findIndex(el => el.key === 'CN') !== -1) {
              item.invoiceSerie = 'CN'
            }

            console.log('createItem assign item')
            this.item = item
            this.editMode = true
            this.modelItemId = 'new_'
          })
          .catch(e => {
            this.$store.commit('alert',
              {
                show: true,
                text: 'Error al cargar datos!',
                type: 'error'
              }
            )
          })
      } else {
        console.log('createItem sale new')

        this.item = {
          saleId: 'new_',
          saleStatus: 'CO',
          clientId: +this.fixedClientId || null,
          aliasClientId: +this.fixedClientId || null,
          personId: null,
          freight: 0,
          offerComment: '',
          saleItems: [{
            // random id
            salesItemId: Math.round((Math.random() * 1000000000)),
            productId: '',
            qty: 0,
            price: 0,
            weight: 0
          }]
        }
        this.editMode = true
        this.modelItemId = 'new_'
      }
    },
    getClientPersonsSelect (clientId) {
      if (!clientId) {
        this.personsSelect = []
        return
      }
      api.getClientPersonsSelect(clientId)
        .then(data => {
          this.personsSelect = data
          // actual selected person is not in personsSelect
          if (data.findIndex(item => item.key === this.item.personId) === -1) {
            this.item.personId = (data[0]) ? data[0].key : null
          }
        })
    },
    getClientAddressesSelect (clientId) {
      if (!clientId) {
        this.addressesSelect = []
        return
      }
      api.getClientAddressesSelect(clientId)
        .then(data => {
          this.addressesSelect = data
          // preset deliveryAddress if it is the only existing address
          if (this.addressesSelect.length === 1 && !this.deliveryAddress) {
            this.deliveryAddress = this.addressesSelect[0]
            this.setDeliveryAddress(this.addressesSelect[0].value)
          }
        })
    },
    setDeliveryAddress (address) {
      console.log('set', address)
      if (address) {
        this.item.shipToCompany = address.company
        this.item.shipToCompany2 = address.company2
        this.item.shipToStreet = address.street
        this.item.shipToContact = address.contact
        this.item.shipToPhone = address.phone
        this.item.shipToZipCode = address.zipCode
        this.item.shipToCity = address.city
        this.item.shipToProvince = address.province
        this.item.shipToIsoCountry = address.isoCountry
      }
    },
    getLastShipNo (saleId) {
      api.getLastShipNo(saleId)
        .then(data => {
          const lastShipNo = data.lastShipNo
          const lastShipDate = data.lastShipDate
          const today = new Date().toISOString().slice(0, -14)
          console.log(lastShipDate, today)
          console.log(parseInt(lastShipNo), lastShipNo)
          this.item.shipNo = '' + (parseInt(lastShipNo) + 1)
          this.item.shipDate = lastShipDate > today ? lastShipDate : today
        })
    },
    getLastInvoiceNo (saleId, invoiceSerie) {
      api.getLastInvoiceNo(saleId, invoiceSerie)
        .then(data => {
          const lastInvoiceNo = data.lastInvoiceNo || 0
          console.log(parseInt(lastInvoiceNo), lastInvoiceNo)
          this.item.invoiceNo = '' + (parseInt(lastInvoiceNo) + 1)
          if (this.item.cn) {
            const today = new Date().toISOString().slice(0, -14)
            this.item.invoiceDate = today
            this.item.saleDate = today
          } else {
            const lastInvoiceDate = data.lastInvoiceDate
            const shipDate = this.item.shipDate
            console.log(lastInvoiceDate, shipDate)
            this.item.invoiceDate = lastInvoiceDate > shipDate ? lastInvoiceDate : shipDate
          }
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .theme--light.v-card > .v-card__text {
    color: inherit;
  }

</style>
