<template>
  <tr
    :class="productClasses"
  >
    <td
      v-if="tableEditMode"
    >
      <v-btn
        :disabled="saleItems.length === 1"
        @click="$emit('deleteRow', item.salesItemId)"
        title="Borrar producto"
        x-small
        fab
        class="elevation-0"
      >
        <v-icon
          color="error"
        >
          {{ mdiDelete }}
        </v-icon>
      </v-btn>
    </td>

    <td
    >
      <v-autocomplete
        v-if="tableEditMode"
        class="hideInputMsg mt-1"
        ref="productId"
        dense
        solo
        flat
        auto-select-first
        :rules="productIdRules"
        v-model="item.productId"
        :items="productsSelect"
        item-text="productId"
        item-value="productId"
        :filter="filterProduct"
        :title="item.productName"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.productId"></v-list-item-title>
            <v-list-item-subtitle v-text="item.productName"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <span
        v-else
      >
        {{ item.productId }}
      </span>
    </td>

    <td
      v-if="!tableEditMode"
      class="d-none d-md-table-cell"
    >
      {{ item.productName }}
    </td>

    <td
      v-if="showPalletId"
    >
      <v-select
        v-if="tableEditMode"
        class="hideInputMsg mt-1"
        dense
        solo
        flat
        auto-select-first
        :rules="palletIdRules"
        v-model="item.palletId"
        :items="productPalletsSelect"
        item-text="palletId"
        item-value="palletId"
        :title="palletIdAvailabilityText(item.palletId)"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.palletId}}</v-list-item-title>
            <!--
            <v-list-item-subtitle>Stock: {{ item.showWeight ? data.item.qty * item.weight : data.item.qty }}{{ item.showWeight ? 'Kg': 'Ud' }}, Entrada: {{ data.item.receptDate | toLocalDate }}</v-list-item-subtitle>
            -->
            <v-list-item-subtitle>{{ palletIdAvailabilityText(item.palletId) }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-select>
      <span
        v-else
      >
        {{ item.palletId }}
      </span>
    </td>

    <td
      class="text-right"
    >
      <v-text-field
        v-if="tableEditMode"
        class="hideInputMsg mt-1 qty"
        :title="productQtyText"
        dense
        solo
        flat
        reverse
        type="number"
        :rules="(item.showWeight) ? qtyRulesKg : qtyRules"
        :prefix="(item.showWeight) ? 'Kg' : 'Uds'"
        v-model="qty"
        @change="changeQty($event)"

      ></v-text-field>
      <!--
      <span
        v-else
        :title="productQtyText"
        class="qty"
      >
        {{ qty }} {{ item.showWeight ? 'Kg' : 'Uds' }}
      </span>
      -->
      <v-tooltip
        v-else
        :disabled="productQtyText === ''"
        bottom
        color="warning"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="qty"
            v-bind="attrs"
            v-on="on"
          >
            {{ qty }} {{ item.showWeight ? 'Kg' : 'Uds' }}
          </span>
        </template>
        <span>{{ productQtyText}}</span>
      </v-tooltip>

    </td>

    <td
      class="text-right"
    >
      <v-text-field
        v-if="tableEditMode && saleStatus !== 'PI'"
        class="hideInputMsg mt-1 price"
        :title="productPriceText"
        dense
        solo
        flat
        reverse
        type="number"
        :rules="priceRules"
        v-model="price"
      ></v-text-field>
      <!--
      <span
        v-else
        :title="productPriceText"

      >
        {{ price | toRounded(2) }}{{ ' ' + defaultCurrencySymbol + (item.showWeight ? '/Kg' : '/Uds') }}
      </span>
      -->

      <v-tooltip
        v-if="!tableEditMode"
        :disabled="productPriceText === ''"
        bottom
        color="warning"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="price"
            v-bind="attrs"
            v-on="on"
          >
            {{ price | toRounded(2) }}{{ ' ' + defaultCurrencySymbol + (item.showWeight ? '/Kg' : '/Uds') }}
          </span>
        </template>
        <span>{{ productPriceText}}</span>
      </v-tooltip>
    </td>

    <td
      v-if="saleStatus !== 'PI' || !tableEditMode"
      class="text-right">{{ item.price * item.qty | toRounded(2) }} {{ ' ' + defaultCurrencySymbol }}
    </td>
  </tr>
</template>

<script>
import { mdiDelete } from '@mdi/js'
import api from '../api'
// import Vue from 'vue' // does not work with this here !?

export default {
  data () {
    return {
      mdiDelete: mdiDelete,
      priceGroups: this.$store.state.priceGroups,
      priceGroupLevels: this.$store.state.priceGroupLevels,

      productPalletsSelect: [],
      productIdRules: [
        (v) => !!v || 'Falta elegir un producto'
      ],
      palletIdRules: [
        (v) => !!v || 'No puede estar en blanco'
      ],
      qtyRulesKg: [
        // positive or negativ integer or decimal with up to 4 digits
        (v) => /^-?\d*\.{0,4}\d+$/.test(v) || 'Debe ser un número.'
      ],
      qtyRules: [
        // positiv or negativ integer
        (v) => /^-?[0-9]+$/.test(v) || 'Debe ser un número entero'
      ],
      priceRules: [
        // positive decimal with up to 4 digits
        (v) => /^\d*\.{0,4}\d+$/.test(v) || 'Debe ser 0 o un número decimal positivo'
      ]
    }
  },
  props: {
    // value represents item passed as model
    value: Object,
    tableEditMode: Boolean,
    saleStatus: String,
    inlineItem: Boolean,
    saleItems: Array,
    saleId: Number,
    availablePalletIds: Array
  },
  mounted () {
    // focus on new item
    if (!this.item.productId) {
      this.$refs.productId.focus()
    }
  },
  computed: {
    item: {
      get () {
        return this.value || {}
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    defaultCurrencySymbol () {
      return this.$store.state.defaultCurrencySymbol
    },
    productsSelect () {
      if (this.saleStatus === 'PI') {
        console.log(this.saleItems)
        // return array of unique productIds
        const uniqueProductIds = [...new Set(this.saleItems.map(obj => obj.productId))]
        // use unique productIds to filter productsSelect
        return this.$store.state.productsSelect.filter(item => uniqueProductIds.includes(item.productId))
      } else {
        return this.$store.state.productsSelect
      }
    },

    showPalletId () {
      return (['PI', 'AL', 'FA', 'EG']).includes(this.saleStatus)
    },
    // base on price as number
    price: {
      get () {
        if (!this.item) {
          return 0
        }
        // avoid rounding errors from multiplication
        return (this.item.showWeight) ? Math.round(100 * this.item.price / this.item.weight) / 100 : this.item.price
      },
      set (value) {
        // avoid that any 3rd digit enters into calculation
        const val = (this.item.showWeight) ? (this.item.weight * Math.round(100 * +value) / 100) : (Math.round(100 * +value) / 100)
        this.$set(this.item, 'price', val)
      }
    },

    qty: {
      get () {
        if (!this.item) {
          return 0
        }
        return (this.item.showWeight) ? (this.item.qty * this.item.weight) : this.item.qty
      },
      set (value) {
        const val = (this.item.showWeight) ? (+value / this.item.weight) : +value
        this.$set(this.item, 'qty', val)
      }
    },
    productClasses () {
      let classText = this.tableEditMode ? '' : 'trNoHighlight'
      if ((['CO', 'PE']).includes(this.saleStatus)) {
        if (this.item.qtyAvailable < this.item.qty) {
          classText += ' stockMissing'
        } else if (this.item.qtyAvailable2 < this.item.qty) {
          classText += ' stockLow'
        }

        if (this.discountPrices && this.discountPrices.levelDiscountPrice && this.discountPrices.maxDiscountPrice) {
          // account for rounding errors
          if (this.price < (this.discountPrices.maxDiscountPrice - 0.01)) {
            classText += ' priceToLow'
          } else if (this.price < (this.discountPrices.levelDiscountPrice - 0.01)) {
            classText += ' priceLow'
          } else if (this.price > (this.discountPrices.levelDiscountPrice + 0.01)) {
            classText += ' priceHigh'
          }
        }
      }
      return classText
    },

    productQtyText () {
      // don't show if empty productId
      if (!this.item.productId) {
        return ''
      }
      let alertText = ''
      if ((['CO', 'PE']).includes(this.saleStatus)) {
        alertText += 'Stock: ' + (this.item.showWeight ? ('' + this.item.qtyAvailable * this.item.weight + ' Kg') : ('' + this.item.qtyAvailable + ' Uds'))
        alertText += ', PEs: ' + (this.item.showWeight ? ('' + this.item.salesQtyOrdered * this.item.weight + ' Kg') : ('' + this.item.salesQtyOrdered + ' Uds'))
        alertText += ', COs: ' + (this.item.showWeight ? ('' + this.item.salesQtyOffered * this.item.weight + ' Kg') : ('' + this.item.salesQtyOffered + ' Uds'))
        alertText += ', Entradas: ' + (this.item.showWeight ? ('' + this.item.entryQtyFuture * this.item.weight + ' Kg') : ('' + this.item.entryQtyFuture + ' Uds'))
      } else if (this.saleStatus === 'PI') {
        alertText += 'Cantidad total pedida para ' + this.item.productId + ': ' + (this.item.showWeight ? ('' + this.item.totalQty * this.item.weight + ' Kg') : ('' + this.item.totalQty + ' Uds'))
      }
      return alertText
    },
    discountPrices () {
      let levelDiscountPrice = 0
      let maxDiscountPrice = 0

      // don't calculate if empty productId
      if (this.item.productId && (['CO', 'PE']).includes(this.saleStatus)) {
        console.log('discountPrices', this.item.priceGroupId)

        const priceGroup = this.priceGroups.find(item => item.priceGroupId === this.item.priceGroupId)
        const priceGroupLevels = this.priceGroupLevels.filter(item => item.priceGroupId === this.item.priceGroupId)
        if (priceGroup && priceGroupLevels && this.qty && this.item.saleListPrice) {
          levelDiscountPrice = this.item.saleListPrice
          maxDiscountPrice = this.item.saleListPrice
          for (let i = 0, len = priceGroupLevels.length; i < len; i++) {
            // attention < maxQty !!! attention maxQty uses qty * kg !!!
            if (priceGroupLevels[i].maxQty === 0 || +this.qty < priceGroupLevels[i].maxQty) {
              levelDiscountPrice = this.item.saleListPrice * (1 - priceGroupLevels[i].discount)
              // get lowest allowed price level
              const minPriceLevel = priceGroupLevels[i + priceGroup.extraDiscountLevels]?.discount || priceGroupLevels[priceGroupLevels.length - 1].discount
              // use minPriceLevel or maxDiscount
              const maxDiscount = priceGroup.maxDiscount > priceGroupLevels[minPriceLevel]?.discount ? priceGroupLevels[minPriceLevel].discount : priceGroup.maxDiscount
              maxDiscountPrice = this.item.saleListPrice * (1 - maxDiscount)
              break
            }
          }
          levelDiscountPrice = (this.item.showWeight ? (levelDiscountPrice / this.item.weight) : levelDiscountPrice)
          maxDiscountPrice = (this.item.showWeight ? (maxDiscountPrice / this.item.weight) : maxDiscountPrice)
        }
      }
      return { levelDiscountPrice, maxDiscountPrice }
    },

    productPriceText () {
      let alertText = ''
      // don't show if empty productId
      if (this.item.productId && (['CO', 'PE']).includes(this.saleStatus)) {
        console.log(this.item, this.discountPrices)
        alertText += 'P. Lista: ' + (this.item.showWeight ? ((this.item.saleListPrice / this.item.weight).toFixed(2) + ' ' + this.defaultCurrencySymbol) + '/Kg' : ((this.item.saleListPrice).toFixed(2) + ' ' + this.defaultCurrencySymbol + '/Ud'))
        if (this.discountPrices && this.discountPrices.levelDiscountPrice) {
          console.log(this.discountPrices.maxDiscountPrice)
          alertText += ', P. Rango: ' + (this.discountPrices.levelDiscountPrice).toFixed(2) + ' ' + this.defaultCurrencySymbol + (this.item.showWeight ? '/Kg' : '/Ud')
          alertText += ', P. Min: ' + (this.discountPrices.maxDiscountPrice).toFixed(2) + ' ' + this.defaultCurrencySymbol + (this.item.showWeight ? '/Kg' : '/Ud')
        }
        console.log(this.item.price, this.item.saleListPrice)
        if (this.item.price < this.item.saleListPrice) {
          alertText += ', Dto. actual: ' + ((1 - +this.item.price / this.item.saleListPrice) * 100).toFixed(2) + '%.'
        }
      }
      return alertText
    }
  },

  watch: {
    'item.productId': {
      handler: function (productId, oldProductId) {
        if (this.tableEditMode && this.saleStatus === 'CO') {
          console.log('productId changed during saleStatus === CO')
          if (productId) {
            // complement item with product info
            const product = this.productsSelect.find(item => item.productId === productId)
            this.item.saleListPrice = product.saleListPrice
            this.item.showWeight = product.showWeight
            this.item.weight = product.weight
            this.item.productName = product.productName
            this.qty = 0
            // manipulate directly item.price because saleListPrice is price per unit and not per kg
            this.$set(this.item, 'price', product.saleListPrice)

            // get availability for new selected product
            // we suppose here that this is a new product
            // in case a product is selected, that already was part of the saved CO, we would get a wrong number for reserved products
            api.getProductAvailability(productId)
              .then(data => {
                this.item.salesQtyOrdered = data.salesQtyOrdered || 0
                this.item.salesQtyOffered = data.salesQtyOffered || 0
                this.item.entryQtyFuture = data.entryQtyFuture || 0
                this.item.priceGroupId = data.priceGroupId || null
                // use set to trigger reactivity
                this.$set(this.item, 'qtyAvailable', data.qtyAvailable || 0)
              })
          } else {
            this.qty = 0
            this.$set(this.item, 'price', 0)
          }
        }
        if (productId && this.tableEditMode && this.saleStatus === 'PI') {
          // console.log('productId changed during saleStatus === PI')
          // refresh productPalletsSelect when adding or changing productIds
          if (this.availablePalletIds) {
            this.productPalletsSelect = this.availablePalletIds.filter((el) => {
              return el.productId === productId
            })
          }
          // complement item with product info and prices from saleItems
          const product = this.saleItems.find(item => item.productId === productId)
          this.item.showWeight = product.showWeight
          this.item.weight = product.weight
          this.item.productName = product.productName
          this.item.price = product.price
        }
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    },
    availablePalletIds: {
      handler: function (availablePalletIds) {
        if (this.tableEditMode) {
          // console.log('tableEditMode activated', availablePalletIds, this.item.productId)
          // refresh productPalletsSelect when changing availablePalletIds (happens after entering tableEditMode)
          this.productPalletsSelect = availablePalletIds.filter((el) => {
            return el.productId === this.item.productId
          })
        }
      },
      // the callback will be called immediately after the start of the observation
      immediate: true
    }

  },

  methods: {
    // we use @change=changeQty() instead of v-model because v-model triggers changes at writing instead of waiting till leaving input
    changeQty (qty) {
      if (this.item.showWeight) {
        this.qty = Math.ceil(qty / this.item.weight) * this.item.weight
      }
      console.log('qty', this.qty, this.item.qty)
    },
    filterProduct (item, queryText, itemText) {
      // search from 'start'
      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) === 0
    },

    palletIdAvailabilityText (palletId) {
      // const startTime = performance.now()
      const pallet = this.productPalletsSelect.find(item => item.palletId === palletId)
      if (!pallet) {
        return ''
      }
      // const endTime = performance.now()
      const availabilityText = 'Stock: ' + (this.item.showWeight ? pallet.qty * this.item.weight : pallet.qty) + (this.item.showWeight ? 'Kg' : 'Ud') + ', Entrada: ' + this.$options.filters.toLocalDate(pallet.receptDate)
      // console.log(`Call to doSomething took ${endTime - startTime} milliseconds`)
      return availabilityText
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
  .stockMissing .qty, .stockMissing .qty input{
    color: #ff5252 !important;
  }
  .stockLow .qty, .stockLow .qty input {
    color: #ffc107 !important;
  }
  .priceToLow .price, .priceToLow .price input{
    color: #ff5252 !important;
  }
  .priceLow .price, .priceLow .price input {
    color: #ffc107 !important;
  }
  .priceHigh .price, .priceHigh .price input {
    color: #4caf50 !important;
  }
</style>
