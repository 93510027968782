<template>
  <v-form
    v-model="valid"
    ref="form"
    lazy-validation
  >
    <v-card
      flat
    >
      <v-card-text
        class="pa-0"
      >
        <v-container
          fluid
          class="pa-1"
        >
            <v-row
              dense
            >
              <v-col
                cols="3"
                sm="2"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  v-model="item.salutation"
                  :rules="requiredRules"
                  label="Saludo"
                  :items="['Sr.', 'Sra.']"
                ></v-select>
              </v-col>
              <v-col
                cols="9"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=20
                  v-model="item.firstname"
                  :rules="nameRules"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col
                cols="9"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=30
                  v-model="item.lastname"
                  :rules="nameRules"
                  label="Apellidos"
                ></v-text-field>
              </v-col>
              <v-col
                cols="3"
                sm="2"
              >
                <v-select
                  :disabled="!editMode || item.mainPers === 1"
                  dense
                  persistent-placeholder
                  filled
                  v-model="item.mainPers"
                  label="Principal"
                  :items="[{text: 'Sí', value: 1}, {text: 'No', value: 0}]"
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                sm="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=30
                  v-model="item.position"
                  label="Cargo"
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="3"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.tags"
                  label="Tags"
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="3"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  v-model="item.marketing"
                  :rules="marketingRules"
                  label="Marketing"
                  :items="marketingSelect"
                  item-text="value"
                  item-value="key"

                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="3"
              >
                <v-select
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  v-model="item.gift"
                  :rules="giftRules"
                  label="Regalo"
                  :items="giftSelect"
                  item-text="value"
                  item-value="key"
                ></v-select>
              </v-col>

            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="3"
                sm="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=3
                  v-model="item.tel1Country"
                  label="Tel1 País"
                ></v-text-field>
              </v-col>
              <v-col
                cols="9"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=30
                  v-model="item.tel1Number"
                  label="Tel1 Número"
                ></v-text-field>
              </v-col>
              <v-col
                cols="3"
                sm="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=3
                  v-model="item.tel2Country"
                  label="Tel2 País"
                ></v-text-field>
              </v-col>
              <v-col
                cols="9"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=30
                  v-model="item.tel2Number"
                  label="Tel2 Número"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=50
                  v-model="item.email"
                  label="Email"
                ></v-text-field>
              </v-col>

              <v-col
                cols="3"
                sm="2"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=3
                  v-model="item.fax1Country"
                  label="Fax País"
                ></v-text-field>
              </v-col>
              <v-col
                cols="9"
                sm="4"
              >
                <v-text-field
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=30
                  v-model="item.fax1Number"
                  label="Fax Número"
                ></v-text-field>
              </v-col>

            </v-row>
            <v-row
              dense
            >

              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  dense
                  :readonly="!editMode"
                  persistent-placeholder
                  filled
                  type="text"
                  maxlength=255
                  v-model="item.persComment"
                  rows="2"
                  label="Comentarios"
                ></v-textarea>
              </v-col>
            </v-row
            >
            <v-row
              dense
              class="ma-0"
            >
              <v-col
                cols="12"
              >
                <div
                  class="text-caption"
                >
                  <span v-if="item.personId === 'new_'">Pendiente de guardar.</span>
                  <span v-else>
                    Creado por {{ item.recCreator }} el {{ item.recCreate | toLocalDateTime }}.
                  </span>
                  <span v-if="item.recChanger">
                    Último cambio por {{ item.recChanger }} el {{ item.recChange | toLocalDateTime }}.
                  </span>
                </div>
              </v-col>
            </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
        class="py-0"
      >
        <v-btn
          v-if="editMode && item.mainPers !== 1 && item.personId !== 'new_' && (user.rights & availablePermissions[editRight])"
          color="error"
          @click="remove"
          text
        >
          Borrar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editMode"
          @click="cancel"
          text
          title="Cancelar y deshacer cambios (Esc)"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="editMode && (user.rights & availablePermissions[editRight])"
          color="primary"
          @click="save"
          text
          title="Guardar cambios (Ctrl + s)"
        >
          Guardar
        </v-btn>
        <v-btn
          v-if="!editMode && (user.rights & availablePermissions[editRight])"
          color="primary"
          text
          title="Editar (F2)"
        >
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import api from '../api'
import EventBus from '@/components/EventBus'
import Vue from 'vue'

export default {
  props: {
    person: Object
  },
  data () {
    return {
      valid: false,
      editMode: false,
      editRight: 'CAN_WRITE',
      item: {},
      user: this.$store.state.user,
      availablePermissions: this.$store.state.availablePermissions,
      marketingSelect: this.$store.state.marketingSelect,
      giftSelect: this.$store.state.giftSelect,
      marketingRules: [
        (v) => this.marketingSelect.findIndex((e) => e.key === v) !== -1 || '¡Se requiere seleccionar un valor!'
      ],
      giftRules: [
        (v) => this.giftSelect.findIndex((e) => e.key === v) !== -1 || '¡Se requiere seleccionar un valor!'
      ],
      requiredRules: [
        (v) => !!v || '¡Información requerida'
      ],
      nameRules: [
        () => (!!this.item.firstname || !!this.item.lastname) || '¡Se requiere al menos un nombre o apellido'
      ]
    }
  },
  computed: {
    personId () {
      return this.person.personId
    }
  },
  mounted () {
    this.init()
    EventBus.$on('ctrlKey_s', (e) => {
      console.log('ctrlKey_s')
      e.preventDefault()
      if (this.editMode) {
        this.save()
      }
    })
    // esc => unselect item
    EventBus.$on('keyEsc', (e) => {
      if (this.editMode) {
        this.cancel()
      }
    })
    EventBus.$on('keyF2', (e) => {
      e.preventDefault()
      this.editMode = true
    })
  },
  beforeDestroy () {
    EventBus.$off('ctrlKey_s')
    EventBus.$off('keyEsc')
    EventBus.$off('keyF2')
  },
  methods: {
    // we don't use ItemMixins here since person Object is not obtained from api but through props
    init () {
      this.item = {
        personId: this.person.personId,
        clientId: this.person.clientId,
        salutation: this.person.salutation,
        firstname: this.person.firstname,
        lastname: this.person.lastname,
        mainPers: this.person.mainPers,
        position: this.person.position,
        tel1Country: this.person.tel1Country,
        tel1Number: this.person.tel1Number,
        tel2Country: this.person.tel2Country,
        tel2Number: this.person.tel2Number,
        fax1Country: this.person.fax1Country,
        fax1Number: this.person.fax1Number,
        email: this.person.email,
        tags: this.person.tags,
        marketing: this.person.marketing,
        gift: this.person.gift,
        persComment: this.person.persComment,
        recCreator: this.person.recCreator,
        recCreate: this.person.recCreate,
        recChanger: this.person.recChanger,
        recChange: this.person.recChange
      }
      // open new and unfinished items directly in edit mode
      Vue.nextTick(() => {
        if (!this.$refs.form.validate()) {
          this.editMode = true
        }
      })
    },
    save () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.item.personId === 'new_') {
        api.createItem('persons', this.item)
          .then(data => {
            this.item = data
            this.editMode = false
            EventBus.$emit('refreshPersons')
            this.$store.commit('alert',
              {
                show: true,
                text: 'Elemento creado',
                type: 'success'
              }
            )
          })
          .catch(e => {
            this.$store.commit('alert',
              {
                show: true,
                text: e.message,
                type: 'error'
              }
            )
          })
      } else {
        api.updateItem('persons', this.personId, this.item)
          .then(data => {
            this.item = data
            this.editMode = false
            EventBus.$emit('refreshPersons')
            this.$store.commit('alert',
              {
                show: true,
                text: 'Elemento actualizado',
                type: 'success'
              }
            )
          })
          .catch(e => {
            this.$store.commit('alert',
              {
                show: true,
                text: e.message,
                type: 'error'
              }
            )
          })
      }
    },
    cancel () {
      // remove unsaved item or reset item
      if (this.person.personId === 'new_') {
        EventBus.$emit('refreshPersons')
      } else {
        this.init()
      }
    },

    remove () {
      api.removeItem('persons', this.personId)
        .then(() => {
          this.$store.commit('alert',
            {
              show: true,
              text: 'Elemento borrado',
              type: 'success'
            }
          )
          EventBus.$emit('refreshPersons')
          Vue.nextTick(() => {
            this.init()
          })
        })
        .catch(e => {
          this.$store.commit('alert',
            {
              show: true,
              text: e.message,
              type: 'error'
            }
          )
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
