<template>
  <v-container
    fluid
    :style="inlineItem ? 'overflow: auto' : ''"
  >
    <v-row
      v-if="inlineItem"
      v-show="selectedItemId"
    >
      <v-col
      >
        <Sale
          ref="sale"
          v-model="selectedItemId"
          :fixedClientId="fixedClientId"
          :inlineItem="inlineItem"
          @refreshList="getItems()"
          @editMode="(state) => {editMode = state}"
        >
        </Sale>
      </v-col>
    </v-row>
    <v-row
      class="overflowLayout"
      :style="!selectedItemId ? 'margin-top: -12px' : ''"
    >
      <v-col
        class="overflowFlex"
      >
        <v-data-table
          :class="'salesTable' + (fixedClientId ? ' hideMatchcodeColumn' : '') + (!invoiceSeriesSelect ? ' hideInvoiceSerieColumn' : '')"
          dense
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :footer-props="footerProps"
          :server-items-length="totalItems"
          @update:options="updateQuery()"
          fixed-header
          mobile-breakpoint="0"
          :height="(inlineItem || fixedClientId) ? 'auto' : 'calc(100vh - 130px)'"
          :value="selected"
          single-select
        >
          <template
            v-slot:[`body.prepend`]="{ headers }"
          >
            <tr
              class="tableFilter"
              py-3
            >
              <td v-for="(header,key) in headers"
                :key="key"
                :class="header.class ? 'pb-1 ' + header.class : 'pb-1'"
              >
                <v-text-field
                  v-if="header.filterType === 'text'"
                  v-model="header.filterValue"
                  hide-details
                  single-line
                  dense
                  clearable
                  @keyup.esc="header.filterValue = ''"
                ></v-text-field>
                <v-select
                  v-else-if="header.filterType === 'select'"
                  flat
                  hide-details
                  dense
                  v-model="header.filterValue"
                  :items="header.selectList"
                ></v-select>
              </td>
            </tr>
          </template>
          <template
            v-slot:item="{ item, index }"
          >
            <tr
              :key="item.saleId"
              @click.stop="showItem(item)"
              :class="(index === selectedIndex) ? 'v-data-table__selected' : ''"
            >
              <td class="text-right">{{ item.saleId }}</td>
              <td>{{ item.saleStatus }}</td>
              <td>{{ item.saleDate.substr(0,10) }}</td>
              <td>{{ item.respPers }}</td>

              <td
                v-if="!fixedClientId"
              >{{ item.matchcode }}
              </td>
              <td class="text-right">{{ item.shipNo }}</td>
              <td
                v-if="invoiceSeriesSelect"
                class="text-right"
              >{{ item.invoiceSerie }}</td>
              <td class="text-right">{{ item.invoiceNo }}</td>
              <td class="saleProds" :title="item.saleProds">{{ item.saleProds }}</td>
              <td class="text-right">{{ item.weight | toRounded(0) }}</td>
              <td class="text-right">{{ item.sales | toRounded(2) }}</td>
              <td class="text-right">{{ item.freight | toRounded(2) }}</td>
              <td>{{ item.shipStatus }}</td>
              <td>{{ item.paid }}</td>
            </tr>
          </template>
          <template v-slot:[`footer.prepend`]
            v-if="!(fixedClientId)"
          >
            <v-btn
              v-if="userCanEdit"
              @click="createItem ()"
              title="Crear pedido (Ctrl. + Ins.)"
              small
              fab
              color="primary"
              elevation="2"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemsMixins from '@/utils/ItemsMixins'
import Sale from '@/views/Sale'
// import EventBus from '@/components/EventBus'

export default {
  mixins: [ItemsMixins],
  components: {
    Sale
  },
  props: {
    // optional fixedClientId passed if called from Client.vue
    fixedClientId: String
  },
  data () {
    return {
      editRight: 'CAN_WRITE',
      itemsName: 'sales', // needed for ItemMixins
      itemName: 'sale',
      inlineItem: this.$store.state.showItemInline,
      invoiceSeriesSelect: this.$store.state.invoiceSeriesSelect,

      headers: [
        {
          text: 'Id',
          align: 'right',
          value: 'saleId',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Status',
          value: 'saleStatus',
          filterType: 'select',
          filterValue: '',
          selectList: [
            { text: 'All', value: '' }, 'FA', 'AL', 'PI', 'PE', 'CO', 'CR', 'EG'
          ],
          filterSearch: 'full'
        },
        {
          text: 'Date',
          value: 'saleDate',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'start'
        },
        {
          text: 'Com.',
          value: 'respPers',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('usersSelect', 'userId'),
          filterSearch: 'full'
        },
        {
          text: 'Match',
          value: 'matchcode',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy',
          class: 'matchcodeColumn'
        },
        {
          text: 'Alb.',
          value: 'shipNo',
          align: 'right',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Serie',
          align: 'right',
          value: 'invoiceSerie',
          filterType: 'select',
          filterValue: '',
          selectList: this.getSelectList('invoiceSeriesSelect'),
          filterSearch: 'full',
          class: 'invoiceSerieColumn'

        },
        {
          text: 'Fra.',
          align: 'right',
          value: 'invoiceNo',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'number'
        },
        {
          text: 'Productos',
          value: 'saleProds',
          filterType: 'text',
          filterValue: '',
          filterSearch: 'fuzzy'
        },
        {
          text: 'Peso',
          value: 'weight',
          align: 'right'
        },
        {
          text: 'Vta',
          value: 'sales',
          align: 'right'
        },
        {
          text: 'Portes',
          value: 'freight',
          align: 'right'
        },
        {
          text: 'Envío',
          value: 'shipStatus',
          filterType: 'select',
          filterValue: '',
          selectList: [
            { text: 'All', value: '' }, '-', 'pdteRecogida', 'enTransito', 'enReparto', 'noEntregado', 'siniestro', 'entregado'
          ],
          filterSearch: 'full'
        },
        {
          text: 'Pagado',
          value: 'paid',
          filterType: 'select',
          filterValue: '',
          selectList: [
            { text: 'All', value: '' }, 'no', 'yes'
          ],
          filterSearch: 'full'
        }
      ]
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .salesTable {
    white-space: nowrap;
  }
  .salesTable .saleProds {
    max-width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .salesTable.hideMatchcodeColumn .matchcodeColumn {
    display: none !important;
  }
  .salesTable.hideInvoiceSerieColumn .invoiceSerieColumn {
    display: none !important;
  }

</style>
