import { render, staticRenderFns } from "./SaleProductsTableItem.vue?vue&type=template&id=33eaa936&"
import script from "./SaleProductsTableItem.vue?vue&type=script&lang=js&"
export * from "./SaleProductsTableItem.vue?vue&type=script&lang=js&"
import style0 from "./SaleProductsTableItem.vue?vue&type=style&index=0&id=33eaa936&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports